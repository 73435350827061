/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable class-methods-use-this */
import { ForbiddenAccessError, NotFoundError } from '../errors';
import type { CurrentUseState, ResultType } from '../types';
import type { Project, ProjectApi, ProjectForm } from './project.interface';

const user1stub = {
  id: 1,
  firstName: 'Wade',
  lastName: 'Wilson',
  login: 'wade.wilson@login.com',
};

const user2stub = {
  id: 2,
  firstName: 'Sam',
  lastName: 'Wilson',
  login: 'sam.wilson@login.com',
};

const user3stub = {
  id: 3,
  firstName: 'Scott',
  lastName: 'Summers',
  login: 'scott.summers@login.com',
};

const user4stub = {
  id: 4,
  firstName: 'Peter',
  lastName: 'Parker',
  login: 'peter.parker@login.com',
};

const user5stub = {
  id: 5,
  firstName: 'Steve',
  lastName: 'Rogers',
  login: 'steve.rogers@login.com',
};

const user6stub = {
  id: 6,
  firstName: 'Tony',
  lastName: 'Stark',
  login: 'tony.stark@login.com',
};

const user7stub = {
  id: 7,
  firstName: 'Bruce',
  lastName: 'Banner',
  login: 'bruce.banner@login.com',
};

const user8stub = {
  id: 8,
  firstName: 'Matt',
  lastName: 'Murdock',
  login: 'matt.murdock@login.com',
};

const user9stub = {
  id: 9,
  firstName: 'Clint',
  lastName: 'Barton',
  login: 'clint.barton@login.com',
};

const user10stub = {
  id: 10,
  firstName: 'Nick',
  lastName: 'Fury',
  login: 'nick.fury@login.com',
};

const user11stub = {
  id: 11,
  firstName: 'Steven',
  lastName: 'Strange',
  login: 'steven.strange@login.com',
};

const user12stub = {
  id: 12,
  firstName: 'Carole',
  lastName: 'Danvers',
  login: 'carol.danvers@login.com',
};

const project1: Project = {
  id: 1,
  name: 'quartier les ulys',
  description: '10 batiments',
  savedAt: new Date(),
  latitude: 48.853279,
  longitude: 2.647952,
  address: '12 Av. du Berry, 91940 Les Ulis',
  users: [
    { ...user1stub, projectRole: 'OWNER' },
    { ...user2stub, projectRole: 'OWNER' },
    { ...user3stub, projectRole: 'OWNER' },
    { ...user4stub, projectRole: 'OWNER' },
    { ...user5stub, projectRole: 'MEMBER' },
    { ...user6stub, projectRole: 'MEMBER' },
    { ...user7stub, projectRole: 'MEMBER' },
    { ...user8stub, projectRole: 'MEMBER' },
    { ...user9stub, projectRole: 'MEMBER' },
    { ...user10stub, projectRole: 'MEMBER' },
    { ...user11stub, projectRole: 'MEMBER' },
    { ...user12stub, projectRole: 'MEMBER' },
  ],
};

const project2: Project = {
  id: 2,
  name: 'le belvédère (test utilisateur qui utilise la projet/la variante)',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam congue varius tristique. Donec sit amet mauris a eros eleifend facilisis vel eget urna. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi sapien sapien, dignissim nec vulputate et, ullamcorper eu mi. Suspendisse potenti. Mauris ligula magna, egestas vel varius eget, rutrum id risus. Nam sed bibendum ante. Vivamus eros ligula, venenatis ac varius at, vulputate et lacus. Duis quam lacus, placerat posuere efficitur vel, dictum ac erat. Aliquam euismod pellentesque aliquet. Duis congue faucibus tortor eu aliquam.\nAenean vitae sem ullamcorper lectus eleifend sagittis. Nulla vitae lectus ultricies, convallis risus sed, molestie urna. In sollicitudin tincidunt risus, quis lobortis tortor tempor ut. Donec fermentum elit nec mauris vulputate, quis suscipit sem interdum. In congue tellus nec orci dignissim sagittis. Phasellus congue at velit et facilisis. Phasellus eu vestibulum arcu, vitae consectetur nunc. Praesent fermentum lorem ac blandit tincidunt. Sed nec placerat sem. Maecenas maximus, enim in interdum aliquet, magna ante hendrerit libero, eget elementum odio nisi a felis. Nam consectetur, eros at consequat tincidunt, magna orci pellentesque ligula, id elementum ante massa eget tortor. Duis rhoncus leo vel urna fringilla auctor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Phasellus tristique efficitur pharetra. Pellentesque dapibus justo lacus, vel consequat velit iaculis ullamcorper.',
  savedAt: new Date(),
  latitude: 48.853279,
  longitude: 2.647952,
  address: '17 promenade du belvédère 77200',
  users: [
    { ...user1stub, projectRole: 'MEMBER' },
    { ...user2stub, projectRole: 'OWNER' },
    { ...user3stub, projectRole: 'MEMBER' },
    { ...user4stub, projectRole: 'MEMBER' },
    { ...user5stub, projectRole: 'MEMBER' },
    { ...user6stub, projectRole: 'MEMBER' },
    { ...user7stub, projectRole: 'MEMBER' },
    { ...user8stub, projectRole: 'MEMBER' },
    { ...user9stub, projectRole: 'MEMBER' },
    { ...user10stub, projectRole: 'MEMBER' },
    { ...user11stub, projectRole: 'MEMBER' },
    { ...user12stub, projectRole: 'MEMBER' },
  ],
};

const project4: Project = {
  id: 4,
  name: 'Projet avec une variante',
  description: 'Un description d un projet avec 1 variante',
  savedAt: new Date(),
  latitude: 48.853279,
  longitude: 2.647952,
  address: 'Adresse fictive a marseille, 13001',
  users: [{ ...user1stub, projectRole: 'OWNER' }],
};

const project5: Project = {
  id: 5,
  name: 'Projet sans aucune variante',
  description: 'C est un projet sans variante',
  savedAt: new Date(),
  latitude: 48.853279,
  longitude: 2.647952,
  address: 'Adresse fictive a marseille, 13001',
  users: [{ ...user1stub, projectRole: 'OWNER' }],
};

const user1projectsIds = [1, 2, 4, 5];
const otherProjectsIds = [3];
const user1ownedProjectsIds = [1, 4];

const availableProject = {
  isCurrentlyUsed: false,
};

const notAvailableProject = {
  isCurrentlyUsed: true,
  usedBy: 'foo.bar@efficacity.com',
};

/*
  We assume that:
    - user1 is connected
    - there is a project3 in which user1 is not involved in.
*/

export const checkProjectsAccess = (projectId: number) => {
  if (![...user1projectsIds, ...otherProjectsIds].includes(projectId)) throw new NotFoundError();
  if (!user1projectsIds.includes(projectId)) throw new ForbiddenAccessError();
};

export class ProjectApiMock implements ProjectApi {
  async createProject(projectData: ProjectForm): Promise<number> {
    return 4;
  }

  async getUserProjects(): Promise<Project[]> {
    return [project1, project2, project4, project5];
  }

  async getProjectById(projectId: number): Promise<Project> {
    checkProjectsAccess(projectId);
    if (projectId === 1) return project1;
    else if (projectId === 2) return project2;
    else if (projectId === 4) return project4;
    return project5;
  }

  async updateProject(projectId: number, projectData: ProjectForm): Promise<void> {
    checkProjectsAccess(projectId);
  }

  async duplicateProject(
    projectIdToDuplicate: number,
    newProjectData: ProjectForm,
    resultsToDuplicate: ResultType,
  ): Promise<number> {
    checkProjectsAccess(projectIdToDuplicate);
    return 4;
  }

  async deleteProject(projectId: number): Promise<void> {
    checkProjectsAccess(projectId);
    if (!user1ownedProjectsIds.includes(projectId)) throw new ForbiddenAccessError();
  }

  async getCurrentUseState(projectId: number): Promise<CurrentUseState> {
    if (projectId === 5) return notAvailableProject; // TODO this is for testing purpose
    return availableProject;
  }
}

import type { LatLngLiteral } from 'leaflet';
import { isEqual } from 'lodash';

/**
 * Utility functions and types exposed/used for the soon-shared components. Each exported member
 * should be moved to the component library along with the first component that depends on it.
 */

// Functionally equivalent to `Array<LatLngLiteral>` with a 'stamp' to ensure rings have been
// checked (and closed if needed) by `toRing`
export type ClosedRing = LatLngLiteral[] & { __isClosedRing: true };

export function toRing(geometry: LatLngLiteral[]): ClosedRing {
  let result: LatLngLiteral[] & { __isClosedRing?: true } = [...geometry];

  const first = geometry[0];
  const last = geometry[geometry.length - 1];
  if (!isEqual(first, last)) {
    result = [...result, first];
  }

  result.__isClosedRing = true;
  return result as ClosedRing;
}

export type PolygonGeometry = ClosedRing[]; // [outer shape, hole 1, hole 2, ...]

import { createRouter as createVueRouter, createWebHistory } from 'vue-router';
import { useToast } from 'vue-toastification';

const newProjectView = () =>
  import(
    /* webpackChunkName: "new-project-view" */ '@/views/dashboard/new-project/NewProjectView.vue'
  );
const projectsListView = () =>
  import(
    /* webpackChunkName: "projects-list-view" */ '@/views/dashboard/projects-list/ProjectsListView.vue'
  );
const projectDetailView = () =>
  import(
    /* webpackChunkName: "project-detail-view" */ '@/views/dashboard/project-detail/ProjectDetailView.vue'
  );
const editProjectView = () =>
  import(
    /* webpackChunkName: "edit-project-view" */ '@/views/dashboard/edit-project/EditProjectView.vue'
  );
const duplicateProjectView = () =>
  import(
    /* webpackChunkName: "duplicate-project-view" */ '@/views/dashboard/duplicate-project/DuplicateProjectView.vue'
  );
const newStudyView = () =>
  import(
    /* webpackChunkName: "new-study-view" */ '@/views/dashboard/new-study/NewStudyView.vue'
  );
const duplicateStudyView = () =>
  import(
    /* webpackChunkName: "duplicate-study-view" */ '@/views/dashboard/duplicate-study/DuplicateStudyView.vue'
  );
const editStudyView = () =>
  import(
    /* webpackChunkName: "edit-study-view" */ '@/views/dashboard/edit-study/EditStudyView.vue'
  );
const studySystemsView = () =>
  import(
    /* webpackChunkName: "study-systems-view" */ '@/views/study-systems/StudySystemsView.vue'
  );
const resultsConsumptionsView = () =>
  import(
    /* webpackChunkName: "results-consumptions-view" */ '@/views/results-consumptions/ResultsConsumptionsView.vue'
  );
const resultsDemandsView = () =>
  import(
    /* webpackChunkName: "results-demands-view" */ '@/views/results-demands/ResultsDemandsView.vue'
  );
const errorView = () => import(/* webpackChunkName: "error-view" */ '@/views/ErrorView.vue');
const buildingsModellingDispatchView = () =>
  import(
    /* webpackChunkName: "buildings-modelling-dispatch-view" */ '@/views/buildings-modelling/BuildingsModellingDispatchView.vue'
  );

export function createRouter() {
  const router = createVueRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
      {
        path: '/',
        name: 'projects-list',
        component: projectsListView,
      },
      {
        path: '/new-project',
        name: 'new-project',
        component: newProjectView,
      },
      {
        path: '/project/:projectid',
        name: 'project-detail',
        component: projectDetailView,
        props: (route) => ({ projectId: parseInt(route.params.projectid as string, 10) }),
      },
      {
        path: '/project/:projectid/new-study',
        name: 'new-study',
        component: newStudyView,
      },
      {
        path: '/project/:projectid/duplicate',
        name: 'duplicate-project',
        component: duplicateProjectView,
      },
      {
        path: '/project/:projectid/edit',
        name: 'edit-project',
        component: editProjectView,
      },
      {
        path: '/study/:studyid/duplicate',
        name: 'duplicate-study',
        component: duplicateStudyView,
      },
      {
        path: '/study/:studyid/edit',
        name: 'edit-study',
        component: editStudyView,
      },
      {
        path: '/study/:studyId(\\d+)/buildings-modelling',
        name: 'buildings-modelling',
        component: buildingsModellingDispatchView,
        props: (route) => ({ studyId: parseInt(route.params.studyId as string, 10) }),
      },
      {
        path: '/study/:studyid/study-systems',
        name: 'study-systems',
        component: studySystemsView,
      },
      {
        path: '/study/:studyid/results-demands',
        name: 'results-demands',
        component: resultsDemandsView,
      },
      {
        path: '/study/:studyid/results-consumptions',
        name: 'results-consumptions',
        component: resultsConsumptionsView,
      },
      {
        path: '/forbidden',
        name: 'forbidden',
        component: errorView,
      },
      {
        path: '/:pathMatch(.*)*',
        name: 'page-not-found',
        component: errorView,
      },
      {
        path: '/error',
        name: 'error',
        component: errorView,
      },
    ],
  });

  router.beforeEach(() => {
    useToast().clear();
  });

  return router;
}

// TODO: ESLint rule to tune globally
/* eslint-disable no-empty-function */

import type { ClosedRing } from '@/components/soon-shared/util';
import {
  geoJSONCoordinatesToPolygonGeometry,
  polygonGeometryToGeoJSONCoordinates,
} from '@/util/geography';
import {
  ResultsToDuplicate,
  type BusySharedStudyPost200ResponseOneOf,
  type DefaultApi,
  type ErrorResponse,
  type ProjectProjectIdStudiesGet200ResponseOneOf,
  type ProjectProjectIdStudiesGet200ResponseOneOfDataInnerAllOfSimulation,
  type SimulationGet200ResponseOneOf,
  type StudyImportBuildingsPost200ResponseOneOf,
  type StudyImportBuildingsPost200ResponseOneOfData,
  type StudyStudyIdGet200ResponseOneOf,
  type Study as StudyTypeApi,
} from '../api-client';
import { ForbiddenAccessError, InvalidResponseError, NotFoundError } from '../errors';
import type { CurrentUseState, ResultType } from '../types';
import type { User } from '../user';
import { convertIfParseError, safeParseDate } from '../util';
import type {
  ImportedBuilding,
  Study,
  StudyApi,
  StudyForm,
  StudyInfo,
  StudySimulationsType,
  StudyStateByStep,
  StudyStepState,
} from './study.interface';

export class TooManyBuildingsToImportError extends Error {}

export class StudyApiFacade implements StudyApi {
  constructor(
    private apiClient: DefaultApi,
    private currentUser: Promise<User>,
  ) {}

  async createStudy(projectId: number, studyData: StudyForm): Promise<number> {
    const userId = (await this.currentUser).id;
    const response = await this.apiClient.studyPost({
      user_id: userId,
      project: projectId,
      name: studyData.name!,
      description: studyData.description!,
    });

    if (response.data.status === false) {
      throw new InvalidResponseError();
    }

    const rawNewStudy = response.data.data as Study;

    return rawNewStudy.id;
  }

  async getStudyInfo(studyId: number): Promise<StudyInfo> {
    const response = await this.apiClient.studyStudyIdGet(studyId);

    if (response.data.status === false) {
      throw new ForbiddenAccessError();
    }

    const rawStudy = (response.data as StudyStudyIdGet200ResponseOneOf).data!;

    return {
      id: rawStudy.id!,
      projectId: rawStudy.project!,
      name: rawStudy.name!,
      description: rawStudy.description!,
      building_image_coordinates: rawStudy.building_image_coordinates
        ? JSON.parse(rawStudy.building_image_coordinates)
        : [],
      network_image_coordinates: rawStudy.network_image_coordinates
        ? JSON.parse(rawStudy.network_image_coordinates)
        : [],
      savedAt: safeParseDate(rawStudy.last_save_time!),
    };
  }

  async getStudyState(studyId: number) {
    const simulationInfoResponse = await this.apiClient.simulationGet(studyId);

    if (simulationInfoResponse.data.status === false) {
      throw new ForbiddenAccessError();
    }

    const rawSimulationInfo: SimulationGet200ResponseOneOf = simulationInfoResponse.data;

    return {
      statesByStep: this.getStatesBySteps(rawSimulationInfo.data!, studyId),
    };
  }

  async getStudy(studyId: number): Promise<Study> {
    const studyInfoResponse = await this.getStudyInfo(studyId);

    const state = await this.getStudyState(studyId);

    return {
      ...studyInfoResponse,
      state,
    };
  }

  private getStatesBySteps(
    rawSimulationInfos: ProjectProjectIdStudiesGet200ResponseOneOfDataInnerAllOfSimulation,
    studyId: number,
  ): StudyStateByStep {
    const { demand, sizing, systems } = rawSimulationInfos;

    // Initial state when no simulation have run yet
    const steps: StudyStateByStep = {
      buildingModelling: { state: 'ACTION_REQUIRED', url: '' },
      resultsBuildingModelling: { state: 'FUTURE', url: '' },
      systemsModelling: { state: 'FUTURE', url: '' },
      sizing: undefined,
      resultsConsumptions: { state: 'FUTURE', url: '' },
    };
    // Utilities to decode raw step statuses
    const isRunning = (rawStep: { status?: string }) =>
      rawStep.status === 'Launched' ||
      rawStep.status === 'Running' ||
      rawStep.status === 'Checking' ||
      rawStep.status === 'Pending';
    const isDone = (rawStep: { status?: string }) => rawStep.status === 'Done';
    const isFailed = (rawStep: { status?: string }) => rawStep.status === 'Failed';
    const isAborted = (rawStep: { status?: string }) => rawStep.status === 'Aborted';

    // Decode step by step. Raw simulation states gives a hint on the edition step
    // before and after.

    // A sizing simulation requires having a demand simulation
    // A systems simulation requires having a demand simulation
    if ((sizing && demand === undefined) || (systems && demand === undefined))
      throw new InvalidResponseError('Simulation process is broken');

    if (demand !== undefined) {
      if (isRunning(demand)) {
        steps.buildingModelling.state = 'LOCKED';
        steps.resultsBuildingModelling.state = 'RUNNING';
      } else if (isDone(demand)) {
        steps.buildingModelling.state = 'SUCCESS';
        steps.resultsBuildingModelling.state = 'SUCCESS';
        steps.systemsModelling.state = 'ACTION_REQUIRED';
      } else if (isFailed(demand)) {
        steps.buildingModelling.state = 'ACTION_REQUIRED';
        steps.resultsBuildingModelling.state = 'ERROR';
      } else if (isAborted(demand)) {
        steps.buildingModelling.state = 'ACTION_REQUIRED';
        steps.resultsBuildingModelling.state = 'CANCELED';
      }
    }
    if (sizing) {
      steps.sizing = {
        state: 'FUTURE',
        url: '',
      };
      if (isRunning(sizing)) {
        steps.buildingModelling.state = 'LOCKED';
        steps.systemsModelling.state = 'LOCKED';
        steps.sizing!.state = 'RUNNING';
      } else if (isDone(sizing)) {
        // The user should re-enter 'systems' modelling once the sizing is done
        steps.systemsModelling.state = 'ACTION_REQUIRED';
        steps.sizing!.state = 'SUCCESS';
      } else if (isFailed(sizing)) {
        steps.systemsModelling.state = 'ACTION_REQUIRED';
        steps.sizing!.state = 'ERROR';
      } else if (isAborted(sizing)) {
        steps.systemsModelling.state = 'ACTION_REQUIRED';
        steps.sizing!.state = 'CANCELED';
      }
    } else delete steps.sizing;
    if (systems) {
      if (isRunning(systems)) {
        steps.buildingModelling.state = 'LOCKED';
        steps.systemsModelling.state = 'LOCKED';
        steps.resultsConsumptions.state = 'RUNNING';
      } else if (isDone(systems)) {
        steps.systemsModelling.state = 'SUCCESS';
        steps.resultsConsumptions.state = 'SUCCESS';
      } else if (isFailed(systems)) {
        steps.systemsModelling.state = 'ACTION_REQUIRED';
        steps.resultsConsumptions.state = 'ERROR';
      } else if (isAborted(systems)) {
        steps.systemsModelling.state = 'ACTION_REQUIRED';
        steps.resultsConsumptions.state = 'CANCELED';
      }
    }

    const valuesWithoutAllowedDuplicates: StudyStepState[] = Object.values(steps).filter(
      (step: StudyStepState) => step !== 'SUCCESS' && step !== 'FUTURE',
    );
    const valuesWithoutAnyDuplicates = [...new Set(valuesWithoutAllowedDuplicates)];

    if (valuesWithoutAllowedDuplicates.length !== valuesWithoutAnyDuplicates.length)
      throw new InvalidResponseError(
        'Having more than one RUNNING|ACTION_REQUIRED|ERROR state is not allowed',
      );
    return this.getStepsWithUrls(studyId, steps);
  }

  // eslint-disable-next-line class-methods-use-this
  private getStepsWithUrls(studyId: number, steps: StudyStateByStep): StudyStateByStep {
    const baseStudyRoute = `/study/${studyId}/`;

    const paths = {
      studyDemands: 'buildings-modelling',
      resultsDemands: 'results-demands',
      studySystems: 'study-systems',
      resultsConsumptions: 'results-consumptions',
    };

    const stepsCopy = { ...steps };

    Object.keys(stepsCopy).forEach((stepName) => {
      if (stepName === 'buildingModelling')
        stepsCopy[stepName]!.url = baseStudyRoute + paths.studyDemands;
      else if (stepName === 'resultsBuildingModelling')
        stepsCopy[stepName]!.url = baseStudyRoute + paths.resultsDemands;
      else if (stepName === 'systemsModelling' || stepName === 'sizing')
        stepsCopy[stepName]!.url = baseStudyRoute + paths.studySystems;
      else if (stepName === 'resultsConsumptions')
        stepsCopy[stepName]!.url = baseStudyRoute + paths.resultsConsumptions;
    });

    return stepsCopy;
  }

  async getProjectStudies(projectId: number): Promise<Study[]> {
    const response = await this.apiClient.projectProjectIdStudiesGet(projectId);

    if (response.data.status === false) {
      // Our user ID comes from the authentication mechanism, and the authentication has worked
      // at that point. Therefore if p10-backend fails with user ID not found, it actually means
      // there is an authorization issue.
      throw new NotFoundError();
    }

    const rawStudies = (response.data as ProjectProjectIdStudiesGet200ResponseOneOf).data!;

    try {
      return rawStudies.map((rawStudy) => ({
        id: rawStudy.id!,
        projectId: rawStudy.project!,
        name: rawStudy.name!,
        description: rawStudy.description!,
        building_image_coordinates: rawStudy.building_image_coordinates
          ? JSON.parse(rawStudy.building_image_coordinates)
          : [],
        network_image_coordinates: rawStudy.network_image_coordinates
          ? JSON.parse(rawStudy.network_image_coordinates)
          : [],
        savedAt: safeParseDate(rawStudy.last_save_time!),
        state: {
          statesByStep: this.getStatesBySteps(rawStudy.simulation!, rawStudy.id!),
        },
      }));
    } catch (error) {
      throw convertIfParseError(error);
    }
  }

  async updateStudy(studyId: number, studyData: StudyForm): Promise<number> {
    const response = await this.apiClient.studyStudyIdUpdatePut(studyId, {
      name: studyData.name,
      description: studyData.description,
      building_image_coordinates: JSON.stringify(studyData.building_image_coordinates),
      network_image_coordinates: JSON.stringify(studyData.network_image_coordinates),
    });

    if (response.data.status === false) {
      throw new InvalidResponseError();
    }

    const rawStudy = (response.data as StudyStudyIdGet200ResponseOneOf).data!;
    return rawStudy.project!;
  }

  async duplicateStudy(
    studyIdToDuplicate: number,
    newStudyData: StudyForm,
    resultsTypesToDuplicate: ResultType,
  ): Promise<Study> {
    let requestResultsTypesToDuplicate: ResultsToDuplicate =
      ResultsToDuplicate.RightSquareBracket;
    if (resultsTypesToDuplicate === 'DEMANDS')
      requestResultsTypesToDuplicate = ResultsToDuplicate.Demand;
    else if (resultsTypesToDuplicate === 'DEMANDS_AND_CONSUMPTIONS') {
      requestResultsTypesToDuplicate = ResultsToDuplicate.Demandsystems;
    }
    const response = await this.apiClient.studyStudyIdDuplicatePost(studyIdToDuplicate, {
      name: newStudyData.name!,
      description: newStudyData.description!,
      results: requestResultsTypesToDuplicate,
    });

    if (response.data.status === false) {
      throw new InvalidResponseError();
    }

    const rawStudy = response.data.data! as StudyTypeApi;

    const state = await this.getStudyState(rawStudy.id!);

    return {
      id: rawStudy.id!,
      projectId: rawStudy.project!,
      name: rawStudy.name!,
      description: rawStudy.description!,
      building_image_coordinates: rawStudy.building_image_coordinates
        ? JSON.parse(rawStudy.building_image_coordinates)
        : [],
      network_image_coordinates: rawStudy.network_image_coordinates
        ? JSON.parse(rawStudy.network_image_coordinates)
        : [],
      savedAt: safeParseDate(rawStudy.last_save_time!),
      state,
    };
  }

  async deleteStudy(studyId: number): Promise<void> {
    const response = await this.apiClient.studyStudyIdDeleteDelete(studyId);

    if (response.data.status === false) {
      if ((response.data as ErrorResponse).message!.includes('one of its simulation is running'))
        throw new ForbiddenAccessError();
      throw new InvalidResponseError();
    }
  }

  async getCurrentUseState(studyId: number): Promise<CurrentUseState> {
    const userId = (await this.currentUser).id;
    const response = await this.apiClient.busySharedStudyPost({
      study_id: studyId,
      user_id: userId,
    });

    if (response.data.status === false) {
      throw new NotFoundError();
    }

    const rawData = (response.data as BusySharedStudyPost200ResponseOneOf).data!;

    return {
      isCurrentlyUsed: rawData.locked!,
      ...(rawData.locked && { usedBy: rawData.user }),
    };
  }

  async importBuildings(importPerimeter: ClosedRing): Promise<ImportedBuilding[]> {
    const response = await this.apiClient.studyImportBuildingsPost({
      geometry: {
        type: 'Polygon',
        coordinates: polygonGeometryToGeoJSONCoordinates([importPerimeter]),
      },
    });

    if (response.data.status === false) {
      const errorResponse = response.data as StudyImportBuildingsPost200ResponseOneOfData;
      if (errorResponse.message!.includes('La zone demandée contient plus de')) {
        throw new TooManyBuildingsToImportError();
      } else {
        throw new InvalidResponseError();
      }
    }

    const rawImportedBuildings = response.data as StudyImportBuildingsPost200ResponseOneOf;

    if (rawImportedBuildings === undefined) {
      throw new InvalidResponseError();
    }

    const importedBuildings: ImportedBuilding[] =
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      rawImportedBuildings.data!.zone_import!.features!.map((feature: any) => ({
        externalId: feature.id,
        properties: feature.properties,
        geometry: geoJSONCoordinatesToPolygonGeometry(feature.geometry.coordinates),
      }));

    return importedBuildings;
  }

  async startSimulation(studyId: number, simulationType: StudySimulationsType): Promise<void> {
    const userId = (await this.currentUser).id;

    const response = await this.apiClient.simulationCreatePost({
      user_id: userId,
      study_id: studyId,
      simulation_type: simulationType,
    });

    if (response.data.status === false) {
      throw new InvalidResponseError();
    }
  }

  async deleteSimulationResults(
    studyId: number,
    simulationType: StudySimulationsType,
  ): Promise<void> {
    const userId = (await this.currentUser).id;

    const response = await this.apiClient.simulationDeleteDelete({
      user_id: userId,
      study_id: studyId,
      simulation_type: simulationType,
    });

    // Beware, the API returns `{status: true}` on succes but not status false on error
    // eg. `{"data":{"code":2,"message":"Simulation matching query does not exist."}}`
    if (response.data.status !== true) {
      throw new InvalidResponseError();
    }
  }
}

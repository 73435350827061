import keycloak from '@/plugins/keycloak';
import { DefaultApi, type UsersLoginSsoPost200ResponseOneOf } from './api-client';
import { InvalidResponseError } from './errors';

export interface User {
  id: number;
  login: string;
  firstName: string;
  lastName: string;
}

/**
 * Asynchronously upsert a user, constructed from the Keycloak state, on the API. Keycloak sends
 * the e-mail in the `login` field of the token and the API uses it as a unique identifier.
 */
export async function upsertKeycloakUser(apiClient: DefaultApi): Promise<User> {
  const tokenData = await keycloak.getTokenData();
  const rawResponse = await apiClient.usersLoginSsoPost({
    Login: tokenData.login,
    uuid: '',
    role: '',
    last_name: tokenData.lastName,
    first_name: tokenData.firstName,
  });
  const rawData = rawResponse.data as UsersLoginSsoPost200ResponseOneOf;

  if (rawData.Status && rawData?.User?.id !== undefined) {
    return {
      id: rawData.User.id,
      login: tokenData.login,
      firstName: tokenData.firstName,
      lastName: tokenData.lastName,
    };
  } else {
    throw new InvalidResponseError('cannot upsert user');
  }
}

export const mockUser: User = {
  id: 1, // Mock implementations also implicitly assume the current user's ID is 1
  login: 'powerdis@efficacity.com',
  firstName: 'Mock',
  lastName: 'User',
};

export const mockUserPromise: Promise<User> = (async () => mockUser)();

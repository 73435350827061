import {
  geoJSONCoordinatesToPolygonGeometry,
  polygonGeometryToGeoJSONCoordinates,
} from '@/util/geography';
import { AxiosError } from 'axios';
import { type DefaultApi, type StudyStudyIdGeojsonGet200ResponseOneOf } from '../api-client';
import { ForbiddenAccessError, InvalidInputError, InvalidResponseError } from '../errors';
import type {
  ThermalZone,
  ThermalZoneApi,
  ThermalZoneProperties,
} from './thermal-zone.interface';

// Properties stored in db, expected but not used directly in the UI.
const rawDefaultThermalZoneProperties = [
  // Properties of Dimosim schema :envelop_simple_demand_ventilation_system.

  'usage_lighting',
  'usage_zone_type',

  'district_linked_0',
  'district_linked_1',
  'district_linked_2',
  'district_linked_3',
  'services_0',
  'services_1',
  'services_2',
  // Properties of PowerDIS schema: district_parameters_schema.
  'calculate_mask',
  'constant_internal_gain_ratio',
  'closed_blind_ratio',
  'closing_deltaT',
  'open_blind_ratio',
  'temperature_blind_closed',

  // Added when demand simulation is completed for systems settings
  'nominal_capacity',
  'nominal_charge_power',
  'nominal_discharge_power',
  'mean_electrical_energy',

  // Properties of PowerDIS schema: geojson_zone_schema.
  'is_initialized',
  'calc_mode',
  'scenario_air_change_rate_set_point',
  'scenario_cooling_set_point',
  'scenario_electric_load',
  'scenario_heating_set_point',
  'scenario_internal_gain_metabolism',
  'scenario_water_flow',

  'load_curve_heating',
  'load_curve_dhw',
  'load_curve_cooling',
  'load_curve_electricity',

  'ventilation_weighting_coefficient',
  // Properties of PowerDIS.
  'id',
  'zone_name',
  'is_geometry_valid',
  // Properties of Dimosim schema :envelop_simple_set_point_simple_systems.
  // Needed in api for check of scenarios/services/systems coherence when scenario is removed.
  'systems_0',
  'systems_1',
  'systems_2',
  'systems_3',
];

const rawThermalZoneProperties = [
  ...rawDefaultThermalZoneProperties,
  // Properties of Dimosim schema :envelop_simple_demand_ventilation_system.
  'name',
  'building_id',
  'altitude',
  'height',
  'floor_count',
  'infiltration_rate',
  'ventilation_system',

  'ExteriorFloor_U_value',
  'ExteriorFloor_inertia',
  'ExteriorFloor_insulation',

  'ExteriorRoof_U_value',
  'ExteriorRoof_inertia',
  'ExteriorRoof_insulation',
  'ExteriorRoof_window_type',
  'ExteriorRoof_window_U_value',
  'ExteriorRoof_window_solar_absorption',
  'ExteriorRoof_window_transmission_factor',
  'ExteriorRoof_window_share',

  'ExteriorWall_U_value',
  'ExteriorWall_inertia',
  'ExteriorWall_insulation',
  'ExteriorWall_window_type',
  'ExteriorWall_window_U_value',
  'ExteriorWall_window_share',
  'ExteriorWall_window_solar_absorption',
  'ExteriorWall_window_transmission_factor',

  // Properties of PowerDIS schema: district_parameters_schema.
  'blind_control_mode',

  // Properties of PowerDIS.
  'building_name',

  'mean_floor_height',
  'gross_floor_area',
  'usable_floor_area_ratio',
  'usable_floor_area',

  'usage_zone_code',
  'performance',
  'year',
  'flying_exterior_floor',
];

function validateId(id: string): void {
  const regex = /^[a-zA-Z]_\d+$/;
  if (!regex.test(id)) {
    throw new InvalidResponseError();
  }
}

const scenarioProperties: Record<string, string> = {
  scenario_heating_set_point: 'heatingScenarioId',
  scenario_cooling_set_point: 'coolingScenarioId',
  scenario_air_change_rate_set_point: 'airChangeRateScenarioId',
  scenario_internal_gain_metabolism: 'internalGainMetabolismScenarioId',
  scenario_water_flow: 'domesticHotWaterScenarioId',
  scenario_electric_load: 'specificElectricityScenarioId',
};

const loadCurveProperties: Record<string, string> = {
  load_curve_heating: 'heatingLoadCurveId',
  load_curve_cooling: 'coolingLoadCurveId',
  load_curve_dhw: 'domesticHotWaterLoadCurveId',
  load_curve_electricity: 'specificElectricityLoadCurveId',
};

const profileProperties: Record<string, string> = {
  ...scenarioProperties,
  ...loadCurveProperties,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function convertRawPropertiesToFacade(properties: any): ThermalZoneProperties {
  validateId(properties.building_id);
  validateId(properties.id);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const validProperties: any = {};
  Object.entries(properties).forEach(([propertyName, propertyvalue]) => {
    if (!rawThermalZoneProperties.includes(propertyName)) {
      // eslint-disable-next-line no-console
      console.error('Unexepected property from api: ', propertyName);
      throw new InvalidResponseError();
    } else {
      if (!rawDefaultThermalZoneProperties.includes(propertyName)) {
        validProperties[propertyName] = propertyvalue;
      }

      if (propertyName === 'name') {
        validProperties.name = properties.zone_name;
      }

      if (propertyName === 'ExteriorWall_window_share') {
        const exteriorWallWindowShare = properties.ExteriorWall_window_share;
        delete validProperties.ExteriorWall_window_share;

        validProperties.ExteriorWall_window_share_N = exteriorWallWindowShare.N;
        validProperties.ExteriorWall_window_share_NE = exteriorWallWindowShare.NE;
        validProperties.ExteriorWall_window_share_NW = exteriorWallWindowShare.NW;
        validProperties.ExteriorWall_window_share_S = exteriorWallWindowShare.S;
        validProperties.ExteriorWall_window_share_SE = exteriorWallWindowShare.SE;
        validProperties.ExteriorWall_window_share_SW = exteriorWallWindowShare.SW;
        validProperties.ExteriorWall_window_share_E = exteriorWallWindowShare.E;
        validProperties.ExteriorWall_window_share_W = exteriorWallWindowShare.W;
      }

      if (propertyName === 'performance') {
        if (propertyvalue === 'Indéfinie') {
          validProperties.performance = 'UNDEFINED';
        } else {
          validProperties.performance = propertyvalue;
        }
      }

      if (propertyName in profileProperties) {
        const profileProperty: string = profileProperties[propertyName];
        validProperties[profileProperty] = Number(properties[propertyName]) || undefined;

        delete validProperties[propertyName];
      }

      // Check profiles consistency
      if (
        properties.scenario_heating_set_point !== null &&
        properties.load_curve_heating !== null
      ) {
        throw new InvalidResponseError();
      }
      if (
        properties.scenario_cooling_set_point !== null &&
        properties.load_curve_cooling !== null
      ) {
        throw new InvalidResponseError();
      }
      if (properties.scenario_water_flow !== null && properties.load_curve_dhw !== null) {
        throw new InvalidResponseError();
      }
      if (
        properties.scenario_electric_load !== null &&
        properties.load_curve_electricity !== null
      ) {
        throw new InvalidResponseError();
      }

      // Set profile mode properties from calc_mode value and/or loadcurve property value
      if (propertyName === 'calc_mode') {
        if (properties.calc_mode === 'dynamic thermal simulation') {
          validProperties.heatingAndCoolingProfileMode = 'SCENARIO';
          validProperties.domesticHotWaterProfileMode = 'SCENARIO';
          validProperties.specificElectricityProfileMode = 'SCENARIO';

          // LoadCurve properties should be undefined
          Object.entries(loadCurveProperties).forEach(([loadCurveProperty]) => {
            if (validProperties[loadCurveProperties[loadCurveProperty]] !== undefined) {
              throw new InvalidResponseError();
            }
          });
        } else {
          validProperties.heatingAndCoolingProfileMode = 'LOAD_CURVE';
          // Heating and cooling scenarios should be undefined
          if (
            properties.scenario_heating_set_point !== undefined ||
            validProperties.heatingScenarioId !== undefined ||
            properties.scenario_cooling_set_point !== undefined ||
            validProperties.coolingScenarioId !== undefined ||
            properties.scenario_air_change_rate_set_point !== undefined ||
            validProperties.airChangeRateScenarioId !== undefined ||
            profileProperties.scenario_internal_gain_metabolism !== undefined ||
            validProperties.internalGainMetabolismScenarioId !== undefined
          ) {
            throw new InvalidResponseError();
          }
          if (properties.load_curve_dhw !== null) {
            validProperties.domesticHotWaterProfileMode = 'LOAD_CURVE';
          }
          if (properties.load_curve_electricity !== null) {
            validProperties.specificElectricityProfileMode = 'LOAD_CURVE';
          }
        }
        delete validProperties[propertyName];
      }
    }
  });

  return validProperties;
}

function convertFacadePropertiesToRaw(
  thermalZoneId: string,
  properties: ThermalZoneProperties,
): object {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const validProperties: any = { ...properties };
  validProperties.zone_name = validProperties.name;
  validProperties.name = thermalZoneId;

  validProperties.id = thermalZoneId;
  validProperties.is_geometry_valid = true;

  validProperties.ExteriorWall_window_share = {
    N: properties.ExteriorWall_window_share_N,
    NE: properties.ExteriorWall_window_share_NE,
    NW: properties.ExteriorWall_window_share_NW,
    S: properties.ExteriorWall_window_share_S,
    SE: properties.ExteriorWall_window_share_SE,
    SW: properties.ExteriorWall_window_share_SW,
    E: properties.ExteriorWall_window_share_E,
    W: properties.ExteriorWall_window_share_W,
  };

  delete validProperties.ExteriorWall_window_share_N;
  delete validProperties.ExteriorWall_window_share_NE;
  delete validProperties.ExteriorWall_window_share_NW;
  delete validProperties.ExteriorWall_window_share_S;
  delete validProperties.ExteriorWall_window_share_SE;
  delete validProperties.ExteriorWall_window_share_SW;
  delete validProperties.ExteriorWall_window_share_E;
  delete validProperties.ExteriorWall_window_share_W;

  if (validProperties.performance === 'UNDEFINED') validProperties.performance = 'Indéfinie';

  // We're not having initialization anymore state in our app thanks to our dynamic defaults selectors
  validProperties.is_initialized = true;

  // Theses properties were added on initialization but not setted in BuildingModelling
  validProperties.systems_0 = null;
  validProperties.systems_1 = null;
  validProperties.systems_2 = null;
  validProperties.systems_3 = validProperties.ventilation_system;

  validProperties.district_linked_0 = null;
  validProperties.district_linked_1 = null;
  validProperties.district_linked_2 = null;
  validProperties.district_linked_3 = null;

  validProperties.services_0 = null;
  validProperties.services_1 = null;
  validProperties.services_2 = null;

  validProperties.usage_lighting = null;
  validProperties.usage_zone_type = null;

  validProperties.calculate_mask = true;
  validProperties.constant_internal_gain_ratio = 0.0;
  validProperties.closed_blind_ratio = 0.1;
  validProperties.closing_deltaT = 3.0;
  validProperties.open_blind_ratio = 1.0;
  validProperties.temperature_blind_closed = 35.0;
  validProperties.ventilation_weighting_coefficient = 1.0;

  validProperties.nominal_capacity = 10000.0;
  validProperties.nominal_charge_power = -5000.0;
  validProperties.nominal_discharge_power = 5000.0;
  validProperties.mean_electrical_energy = 0.0;

  // Check inconsistent mode config
  if (
    validProperties.heatingAndCoolingProfileMode === 'SCENARIO' &&
    (validProperties.domesticHotWaterProfileMode === 'LOAD_CURVE' ||
      validProperties.specificElectricityProfileMode === 'LOAD_CURVE')
  ) {
    throw new InvalidInputError();
  }
  if (validProperties.heatingAndCoolingProfileMode === 'SCENARIO') {
    validProperties.calc_mode = 'dynamic thermal simulation';
    validProperties.heatingLoadCurveId = undefined;
    validProperties.coolingLoadCurveId = undefined;
    validProperties.domesticHotWaterLoadCurveId = undefined;
    validProperties.specificElectricityLoadCurveId = undefined;
    if (validProperties.heatingScenarioId !== undefined) {
      validProperties.services_0 = 'Heating';
    }
    if (validProperties.coolingScenarioId !== undefined) {
      validProperties.services_1 = 'Cooling';
    }
    if (validProperties.domesticHotWaterScenarioId !== undefined) {
      validProperties.services_2 = 'DHW';
    }
  } else {
    validProperties.calc_mode = 'load_curve';
    validProperties.heatingScenarioId = undefined;
    validProperties.coolingScenarioId = undefined;
    if (validProperties.domesticHotWaterProfileMode === 'SCENARIO') {
      validProperties.domesticHotWaterLoadCurveId = undefined;
    } else {
      validProperties.domesticHotWaterScenarioId = undefined;
    }
    if (validProperties.specificElectricityProfileMode === 'SCENARIO') {
      validProperties.specificElectricityLoadCurveId = undefined;
    } else {
      validProperties.specificElectricityScenarioId = undefined;
    }
  }
  delete validProperties.heatingAndCoolingProfileMode;
  delete validProperties.domesticHotWaterProfileMode;
  delete validProperties.specificElectricityProfileMode;

  // Set profiles properties with legacy names
  Object.entries(profileProperties).forEach(([legacyProperty, property]) => {
    const profileProperty: string = validProperties[property];
    validProperties[legacyProperty] = JSON.stringify(profileProperty) || null;
    delete validProperties[property];
  });

  return validProperties;
}

class ThermalZoneApiFacade implements ThermalZoneApi {
  // eslint-disable-next-line no-empty-function
  constructor(private apiClient: DefaultApi) {}

  async getStudyThermalZones(studyId: number): Promise<ThermalZone[]> {
    try {
      const response = await this.apiClient.studyStudyIdGeojsonGet(studyId, true);

      if (response.data.status === false) {
        throw new InvalidInputError();
      }

      const rawThermalZones = (response.data as StudyStudyIdGeojsonGet200ResponseOneOf).data;

      if (rawThermalZones === undefined) {
        throw new InvalidResponseError();
      }

      const features = rawThermalZones.thermal_zone.features.map((feature) => ({
        id: feature.properties.id,
        properties: convertRawPropertiesToFacade(feature.properties),
        geometry: geoJSONCoordinatesToPolygonGeometry(
          feature.geometry.coordinates as Array<Array<[number, number]>>,
        ),
      }));
      return features;
    } catch (error) {
      if (error instanceof AxiosError && error?.response?.status === 403) {
        throw new ForbiddenAccessError();
      } else {
        throw new InvalidResponseError();
      }
    }
  }

  async persistStudyThermalZones(studyId: number, thermalZones: ThermalZone[]): Promise<void> {
    const response = await this.apiClient.studyStudyIdUpdatePut(studyId, {
      geojson_zone: {
        type: 'FeatureCollection',
        crs: {
          properties: {
            name: 'epsg:4326',
          },
          type: 'name',
        },
        features: thermalZones.map((thermalZone) => ({
          type: 'Feature',
          properties: convertFacadePropertiesToRaw(thermalZone.id, thermalZone.properties),
          geometry: {
            type: 'Polygon',
            coordinates: polygonGeometryToGeoJSONCoordinates(thermalZone.geometry),
          },
        })),
      },
    });

    if (response.data.status === false) {
      throw new InvalidInputError();
    }
  }
}

const _testSubjects = {
  convertFacadePropertiesToRaw,
  convertRawPropertiesToFacade,
  validateId,
};

export { _testSubjects, ThermalZoneApiFacade };

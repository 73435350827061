/* eslint-disable @typescript-eslint/no-explicit-any */
import { h, type ComponentPublicInstance, type FunctionalComponent } from 'vue';

const aliases: { [componentName: string]: any } = {
  // Declare the icons here
};

// Types duplicated from Vuetify (they are not exported)

type JSXComponent<Props = any> =
  | {
      new (): ComponentPublicInstance<Props>;
    }
  | FunctionalComponent<Props>;
type IconValue = string | Array<string | [path: string, opacity: number]> | JSXComponent;

const customIcons = {
  component: (props: { icon?: IconValue; [_: string]: any }) =>
    h(aliases[props.icon as unknown as string]),
};

export { customIcons };

/**
 * API Client
 * ==========
 *
 * Expose a TypeScript interface that reflects our future(*) API, inspired by UrbanPrint and
 * EnergyMapper when relevant.
 *
 * (*) The HTTP API exposed by p10-backend has many flaws: 200 response even for some errors,
 * inconsistent namings, useless fields, etc. For now we don't want to fix them, so we enclose
 * them in a nice facade. This way, our components are easy to migrate to a cleaned API.
 *
 * Internally, we use openapi-generator to generate client classes in `api-client` from the
 * OpenAPI schema declared in p10-backend. Then we provide facade classes, they implement the
 * nice interface and call the generated clients / convert the data formats.
 *
 * To use it in your components, call `useApi()`. Depending on build-time configuration, it
 * provides either facades to the real API or mock implementations.
 *
 * Configuration
 * -------------
 *
 * At the build time, set the environment variable `VITE_USE_API_MOCKS` with:
 *   - "true": use the API mocks (typically for automated tests);
 *   - "toggle": use the real API by default, and allow the user to call `toggleApiMocks()` in
 *     the browser's developer console. The setting is memorized in the local storage;
 *   - any other value or not set: use the real API.
 *
 * Current user & Session management
 * ---------------------------------
 *
 * In real mode, you must initialize Keycloak before calling `useApi()`.
 *
 * All API operations expect a Bearer token. Some operations also take a `userId` parameter that
 * is the current user. However Keycloak only knows the current user's email, not its numeric ID.
 *
 * Therefore, when you call `useApi()` for the first time, it calls the API to upsert a user
 * using the data returned by Keycloak, and exposes the result (including the user ID) to the
 * facade classes.
 *
 * Module structure
 * ----------------
 *
 * `api-client` is the generated code, see /generate-api-client.sh. Other folders correspond to
 * the categories of API endpoints. Each category exposes an interface and the mock + real facade
 * implementations.
 *
 * HTTP errors thrown by the generated api-client should be transformed into the higher-level
 * ones of `errors.ts`.
 */

import keycloak from '@/plugins/keycloak';
import { Configuration, DefaultApi } from './api-client';
import { LoadCurvesApiFacade } from './load-curve/load-curve.facade';
import type { LoadCurveApi } from './load-curve/load-curve.interface';
import { LoadCurveApiMock } from './load-curve/load-curve.mock';
import { ProjectApiFacade } from './project/project.facade';
import type { ProjectApi } from './project/project.interface';
import { ProjectApiMock } from './project/project.mock';
import { ScenarioApiFacade } from './scenario/scenario.facade';
import type { ScenarioApi } from './scenario/scenario.interface';
import { ScenarioApiMock } from './scenario/scenario.mock';
import { StudyApiFacade } from './study/study.facade';
import type { StudyApi } from './study/study.interface';
import { StudyApiMock } from './study/study.mock';
import { ThermalZoneApiFacade } from './thermal-zone/thermal-zone.facade';
import type { ThermalZoneApi } from './thermal-zone/thermal-zone.interface';
import { ThermalZoneApiMock } from './thermal-zone/thermal-zone.mock';
import { mockUserPromise, upsertKeycloakUser, type User } from './user';

interface Api {
  // Context
  currentUser: Promise<User>;

  // API sections
  project: ProjectApi;
  study: StudyApi;
  thermalZone: ThermalZoneApi;
  scenario: ScenarioApi;
  loadCurve: LoadCurveApi;
}

// #region Determination of the operation mode (real / mocks)

let usingMocks: boolean;

function readApiMocksSetting(): boolean {
  return window.localStorage.getItem('apiMocks') === 'true';
}

function toggleApiMocks() {
  if (readApiMocksSetting()) {
    window.localStorage.removeItem('apiMocks');
  } else {
    window.localStorage.setItem('apiMocks', 'true');
  }
  window.location.reload();
}

function isUsingMocks(): boolean {
  return usingMocks;
}

if (import.meta.env.VITE_USE_API_MOCKS === 'true') {
  usingMocks = true;
} else if (import.meta.env.VITE_USE_API_MOCKS === 'toggle') {
  usingMocks = readApiMocksSetting();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as unknown as any).toggleApiMocks = toggleApiMocks;
  // eslint-disable-next-line no-console
  console.log(
    `Using ${usingMocks ? 'API mocks' : 'the real API'}. To switch, call toggleApiMocks().`,
  );
} else {
  usingMocks = false;
}

// #endregion

// #region `useApi()` composable

function makeApiWithMocks(): Api {
  return {
    currentUser: mockUserPromise,
    project: new ProjectApiMock(),
    study: new StudyApiMock(),
    thermalZone: new ThermalZoneApiMock(),
    scenario: new ScenarioApiMock(),
    loadCurve: new LoadCurveApiMock(),
  };
}

function makeApiWithFacades(apiClient: DefaultApi, currentUser: Promise<User>): Api {
  return {
    currentUser,
    project: new ProjectApiFacade(apiClient, currentUser),
    study: new StudyApiFacade(apiClient, currentUser),
    thermalZone: new ThermalZoneApiFacade(apiClient),
    scenario: new ScenarioApiFacade(apiClient, currentUser),
    loadCurve: new LoadCurvesApiFacade(apiClient, currentUser),
  };
}

// State, at the global application level, managed by `useApi()`
let apiClientRef: DefaultApi;
let currentUser: Promise<User>;

function useApi(): Api {
  if (usingMocks) {
    return makeApiWithMocks();
  }

  // Otherwise, we are using the real API

  if (apiClientRef === undefined) {
    // Initialize the API client, set it to obtain the Bearer token from Keycloak at each call
    apiClientRef = new DefaultApi(new Configuration({ accessToken: keycloak.getToken }));
  }

  if (currentUser === undefined) {
    currentUser = upsertKeycloakUser(apiClientRef);
    // The current user is passed to facades as a promise so that upcoming async API calls can
    // wait for it
  }

  return makeApiWithFacades(apiClientRef, currentUser);
}

// #endregion

export { type Api as IApi, useApi, isUsingMocks };

import type { UsageCodeToScenarioId } from '@/api/scenario/scenario.interface';
import type {
  BlindControlModeType,
  Performance,
  ThermalZoneProperties,
  UsageCode,
} from '@/api/thermal-zone/thermal-zone.interface';
import _ from 'lodash';
import { getInfiltrationRate } from './thermal-zone-defaults.infiltration-rate';
import { lookupDefaultTabulatedValues } from './thermal-zone-defaults.table';

// #region Static defaults

// Keep same order as in ThermalZoneProperties
type KeyWithStaticDefault =
  | 'altitude'
  | 'floor_count'
  | 'mean_floor_height'
  | 'usable_floor_area_ratio'
  | 'usage_zone_code'
  | 'performance'
  | 'year'
  | 'ventilation_system'
  | 'ExteriorRoof_inertia'
  | 'ExteriorRoof_insulation'
  | 'ExteriorRoof_window_share'
  | 'ExteriorWall_inertia'
  | 'ExteriorWall_insulation'
  | 'ExteriorFloor_inertia'
  | 'ExteriorFloor_insulation'
  | 'blind_control_mode'
  | 'heatingAndCoolingProfileMode'
  | 'heatingLoadCurveId'
  | 'coolingLoadCurveId'
  | 'domesticHotWaterProfileMode'
  | 'domesticHotWaterLoadCurveId'
  | 'specificElectricityProfileMode'
  | 'specificElectricityLoadCurveId'
  | 'flying_exterior_floor';

type PropertiesWithStaticDefault = Pick<ThermalZoneProperties, KeyWithStaticDefault>;

// Keep same order as in ThermalZoneProperties
const staticDefaults: PropertiesWithStaticDefault = {
  altitude: 0,
  floor_count: 1,
  mean_floor_height: 2.7,
  usable_floor_area_ratio: 0.7,

  usage_zone_code: 'B00' as UsageCode,
  performance: 'UNDEFINED' as Performance,
  year: new Date().getFullYear(),

  ventilation_system: 'ZoneSingleFlowVentilation',

  ExteriorRoof_inertia: 'mid',
  ExteriorRoof_insulation: 'indoor',
  ExteriorRoof_window_share: 0.0,

  ExteriorWall_inertia: 'mid',
  ExteriorWall_insulation: 'indoor',

  ExteriorFloor_inertia: 'mid',
  ExteriorFloor_insulation: 'indoor',

  blind_control_mode: 1.0 as BlindControlModeType,

  heatingAndCoolingProfileMode: 'SCENARIO',
  heatingLoadCurveId: undefined,
  coolingLoadCurveId: undefined,

  domesticHotWaterProfileMode: 'SCENARIO',
  domesticHotWaterLoadCurveId: undefined,

  specificElectricityProfileMode: 'SCENARIO',
  specificElectricityLoadCurveId: undefined,

  flying_exterior_floor: false,
};

// #endregion

// #region Dynamic defaults

export type DynamicDefaultSelectors = Pick<
  ThermalZoneProperties,
  'performance' | 'usage_zone_code' | 'mean_floor_height' | 'floor_count'
>;

export function extractDynamicDefaultSelectors(
  thermalZoneStub: Partial<ThermalZoneProperties> & DynamicDefaultSelectors,
): DynamicDefaultSelectors {
  return _.pick(thermalZoneStub, [
    'performance',
    'usage_zone_code',
    'mean_floor_height',
    'floor_count',
  ]);
}

// Keep same order as in ThermalZoneProperties
export const dynamicDefaultKeys = [
  'infiltration_rate',

  'ExteriorRoof_U_value',
  'ExteriorRoof_window_type',

  'ExteriorWall_U_value',
  'ExteriorWall_window_type',
  // Same values for the 8 directions
  'ExteriorWall_window_share_N',
  'ExteriorWall_window_share_NE',
  'ExteriorWall_window_share_NW',
  'ExteriorWall_window_share_S',
  'ExteriorWall_window_share_SE',
  'ExteriorWall_window_share_SW',
  'ExteriorWall_window_share_E',
  'ExteriorWall_window_share_W',

  'ExteriorFloor_U_value',

  'heatingScenarioId',
  'coolingScenarioId',
  'airChangeRateScenarioId',
  'internalGainMetabolismScenarioId',
  'domesticHotWaterScenarioId',
  'specificElectricityScenarioId',
] as const;

type KeyWithDynamicDefault = (typeof dynamicDefaultKeys)[number];

type PropertiesWithDynamicDefault = Pick<ThermalZoneProperties, KeyWithDynamicDefault>;

export type DefaultScenarioIds = {
  heating: UsageCodeToScenarioId;
  cooling: UsageCodeToScenarioId;
  airChangeRate: UsageCodeToScenarioId;
  internalGainMetabolism: UsageCodeToScenarioId;
  domesticHotWater: UsageCodeToScenarioId;
  specificElectricity: UsageCodeToScenarioId;
};

/**
 * @param defaultScenarioIds If the `defaultScenarioIds` argument is not set, return `undefined`
 *   for each scenario ID. We don't always need these defaults (e.g. when creating a thermal
 *   zone).
 */
export function getDynamicDefaults(
  selectors: DynamicDefaultSelectors,

  defaultScenarioIds?: DefaultScenarioIds,
): PropertiesWithDynamicDefault {
  const { windowType, windowShare, roofU, wallU, floorU } = lookupDefaultTabulatedValues(
    selectors.performance,
    selectors.usage_zone_code,
  );

  const scenarioIdProperties = defaultScenarioIds
    ? {
        heatingScenarioId: defaultScenarioIds.heating[selectors.usage_zone_code],
        coolingScenarioId: defaultScenarioIds.cooling[selectors.usage_zone_code],
        airChangeRateScenarioId: defaultScenarioIds.airChangeRate[selectors.usage_zone_code],
        internalGainMetabolismScenarioId:
          defaultScenarioIds.internalGainMetabolism[selectors.usage_zone_code],
        domesticHotWaterScenarioId:
          defaultScenarioIds.domesticHotWater[selectors.usage_zone_code],
        specificElectricityScenarioId:
          defaultScenarioIds.specificElectricity[selectors.usage_zone_code],
      }
    : {
        heatingScenarioId: undefined,
        coolingScenarioId: undefined,
        airChangeRateScenarioId: undefined,
        internalGainMetabolismScenarioId: undefined,
        domesticHotWaterScenarioId: undefined,
        specificElectricityScenarioId: undefined,
      };

  return {
    infiltration_rate: getInfiltrationRate(
      selectors.usage_zone_code,
      windowType,
      selectors.mean_floor_height,
      selectors.floor_count,
    ),

    ExteriorRoof_U_value: roofU,
    ExteriorRoof_window_type: windowType,

    ExteriorWall_U_value: wallU,
    ExteriorWall_window_type: windowType,
    ExteriorWall_window_share_N: windowShare,
    ExteriorWall_window_share_NE: windowShare,
    ExteriorWall_window_share_NW: windowShare,
    ExteriorWall_window_share_S: windowShare,
    ExteriorWall_window_share_SE: windowShare,
    ExteriorWall_window_share_SW: windowShare,
    ExteriorWall_window_share_E: windowShare,
    ExteriorWall_window_share_W: windowShare,

    ExteriorFloor_U_value: floorU,

    ...scenarioIdProperties,
  };
}

// #endregion

// Make sure static and dynamic keys do not overlap
/* eslint-disable */
(shouldBeNever: KeyWithStaticDefault & KeyWithDynamicDefault) => {
  const x: never = shouldBeNever;
};
/* eslint-enable */

export type PropertiesWithExplicitDefault = PropertiesWithStaticDefault &
  PropertiesWithDynamicDefault;

export type KeyWithExplicitDefault = KeyWithStaticDefault | KeyWithDynamicDefault;

/**
 * @param selectors If not set, take the selector values from the static defaults.
 * @param defaultScenarioIds If not set, return `undefined` for each scenario ID. We don't always
 *   need these defaults (e.g. when creating a thermal zone).
 */
export function getAllDefaults(
  selectors?: DynamicDefaultSelectors,
  defaultScenarioIds?: DefaultScenarioIds,
): PropertiesWithExplicitDefault {
  return {
    ...staticDefaults,
    ...getDynamicDefaults(
      selectors ?? extractDynamicDefaultSelectors(staticDefaults),
      defaultScenarioIds,
    ),
  };
}

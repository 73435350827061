import {
  windowTypeValues,
  type ThermalZoneProperties,
} from '@/api/thermal-zone/thermal-zone.interface';
import type { PolygonGeometry } from '@/components/soon-shared/util';
import _ from 'lodash';
import { getArea } from './geography';

// #region Types

// Input (the keys or properties needed for the computation)

export type InputForComputedProperties = Partial<ThermalZoneProperties> &
  Pick<
    ThermalZoneProperties,
    | 'floor_count'
    | 'mean_floor_height'
    | 'usable_floor_area_ratio'
    | 'ExteriorRoof_window_type'
    | 'ExteriorWall_window_type'
  >;

// Output (the result of the computation; some properties being conditionally computed depending
// on the inputs)

export type AlwaysComputedKey = 'height' | 'gross_floor_area' | 'usable_floor_area';

type SometimesComputedKey =
  | 'ExteriorRoof_window_U_value'
  | 'ExteriorRoof_window_solar_absorption'
  | 'ExteriorRoof_window_transmission_factor'
  | 'ExteriorWall_window_U_value'
  | 'ExteriorWall_window_solar_absorption'
  | 'ExteriorWall_window_transmission_factor';

export type ComputedProperties = Pick<ThermalZoneProperties, AlwaysComputedKey> &
  Partial<Pick<ThermalZoneProperties, SometimesComputedKey>>;

// 'maximal' output when all possible computed properties are actually computed
export type ComputableProperties = Pick<
  ThermalZoneProperties,
  AlwaysComputedKey | SometimesComputedKey
>;

export type NeverComputedProperties = Omit<
  ThermalZoneProperties,
  AlwaysComputedKey | SometimesComputedKey
>;

// #endregion

// #region Utilities

export function computeHeight(floorCount: number, meanFloorHeight: number): number {
  return _.round(floorCount * meanFloorHeight, 2);
}

// #endregion

// #region Computation rules

export function getRelevantComputedProperties(
  baseGeometry: PolygonGeometry,
  baseProperties: InputForComputedProperties,
): ComputedProperties {
  const newProperties: Partial<ComputedProperties> = {};

  // Geometric properties

  newProperties.height = computeHeight(
    baseProperties.floor_count,
    baseProperties.mean_floor_height,
  );

  newProperties.gross_floor_area = _.round(getArea(baseGeometry) * baseProperties.floor_count);

  newProperties.usable_floor_area = _.round(
    newProperties.gross_floor_area * baseProperties.usable_floor_area_ratio,
  );

  // Roof windows type properties

  if (baseProperties.ExteriorRoof_window_type in windowTypeValues) {
    newProperties.ExteriorRoof_window_U_value =
      windowTypeValues[baseProperties.ExteriorRoof_window_type].window_U_value;
    newProperties.ExteriorRoof_window_solar_absorption =
      windowTypeValues[baseProperties.ExteriorRoof_window_type].window_solar_absorption;
    newProperties.ExteriorRoof_window_transmission_factor =
      windowTypeValues[baseProperties.ExteriorRoof_window_type].window_transmission_factor;
  }

  // Walls windows type properties

  if (baseProperties.ExteriorWall_window_type in windowTypeValues) {
    newProperties.ExteriorWall_window_U_value =
      windowTypeValues[baseProperties.ExteriorWall_window_type].window_U_value;
    newProperties.ExteriorWall_window_solar_absorption =
      windowTypeValues[baseProperties.ExteriorWall_window_type].window_solar_absorption;
    newProperties.ExteriorWall_window_transmission_factor =
      windowTypeValues[baseProperties.ExteriorWall_window_type].window_transmission_factor;
  }

  return newProperties as ComputedProperties;
}

export function getComputableProperties(
  baseGeometry: PolygonGeometry,
  baseProperties: InputForComputedProperties,
): ComputableProperties {
  if (
    !(baseProperties.ExteriorRoof_window_type in windowTypeValues) ||
    !(baseProperties.ExteriorWall_window_type in windowTypeValues)
  ) {
    throw new Error("Due to custom window types, some computable properties won't be computed");
  }

  return getRelevantComputedProperties(baseGeometry, baseProperties) as ComputableProperties;
}

// #endregion

/* eslint-disable no-empty-function */

import _ from 'lodash';
import type {
  DefaultApi,
  Scenario as RawScenario,
  ScenarioCategoryEnum,
  ScenarioGetAllGet200ResponseOneOf,
} from '../api-client';
import { InvalidInputError } from '../errors';
import { usageCodeOptions, type UsageCode } from '../thermal-zone/thermal-zone.interface';
import type { User } from '../user';
import type {
  AllScenarios,
  Scenario,
  ScenarioApi,
  ScenariosByScope,
  UsageCodeToScenarioId,
} from './scenario.interface';

export class ScenarioApiFacade implements ScenarioApi {
  constructor(
    private apiClient: DefaultApi,
    private currentUser: Promise<User>,
  ) {}

  async getAllScenarios(projectId: number): Promise<AllScenarios> {
    const user = await this.currentUser;
    const response = await this.apiClient.scenarioGetAllGet(user.id, true, projectId);
    if (response.data.status === false) {
      throw new InvalidInputError();
    }

    const responseData = (response.data as ScenarioGetAllGet200ResponseOneOf).data!;

    const convertToScenario = (rawScenario: RawScenario): Scenario => {
      const isKnownTypology = (usageCodeOptions as readonly string[]).includes(
        rawScenario.code_typology,
      );
      return {
        ...rawScenario,
        _key: _.parseInt(rawScenario._key),
        code_typology: isKnownTypology ? (rawScenario.code_typology as UsageCode) : undefined,
      };
    };

    const flatScenarios = {
      default: responseData.default.map(convertToScenario),
      user: responseData.user.map(convertToScenario),
      project: (responseData.project ?? []).map(convertToScenario),
    };

    const packFromCategory = (category: ScenarioCategoryEnum): ScenariosByScope => ({
      default: _.filter(flatScenarios.default, { category }),
      user: _.filter(flatScenarios.user, { category }),
      project: _.filter(flatScenarios.project, { category }),
    });

    return {
      heating: packFromCategory('heating_set_point'),
      cooling: packFromCategory('cooling_set_point'),
      airChangeRate: packFromCategory('air_change_rate_set_point'),
      internalGainMetabolism: packFromCategory('internal_gain_metabolism'),
      domesticHotWater: packFromCategory('water_flow'),
      specificElectricity: packFromCategory('electric_load'),
    };
  }
}

export const getScenarioIdsByUsageCode = (scenarios: Scenario[]): UsageCodeToScenarioId =>
  _.chain(scenarios)
    .filter((scenario) => scenario.code_typology !== undefined)
    .groupBy('code_typology')
    .mapValues(_.first)
    .mapValues('_key')
    .value();

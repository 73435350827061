import type {
  Performance,
  StandardWindowType,
  UsageCode,
} from '@/api/thermal-zone/thermal-zone.interface';

/*
 * Table of reference data.
 *
 * Source: data extracted from ApiTools microservice. We'll remove this data when the legacy
 * p10-front-app no longer runs. It was originally generated from RT2012 documents using an
 * unmaintained tool made by Romain Bonabe de Rougé.
 *
 * The return type here is as short as possible so that each `return` statement fits on one line
 * for readability. See `lookupEnvelopeDefaults` below for the exported format.
 */
function lookupParts(
  performance: Performance,
  usageCode: UsageCode,
): {
  wt: StandardWindowType;
  ws: number; // window share
  roofU: number;
  wallU: number;
  floorU: number;
} {
  const defaultU = { roofU: 1.0, wallU: 1.0, floorU: 1.0 };

  if (usageCode === 'B00') {
    return { wt: 'double_glazing', ws: 0.1, ...defaultU };
  }
  if (usageCode === 'B01') {
    if (performance === '0-1938')
      return { wt: 'single_glazing', ws: 0.1, roofU: 0.8, wallU: 2.0, floorU: 1.5 };
    if (performance === '1939-1968')
      return { wt: 'single_glazing', ws: 0.1, roofU: 0.8, wallU: 2.0, floorU: 1.5 };
    if (performance === '1969-1975')
      return { wt: 'double_glazing', ws: 0.1, roofU: 0.6, wallU: 0.8, floorU: 1.2 };
    if (performance === '1976-1983')
      return { wt: 'double_glazing', ws: 0.1, roofU: 0.6, wallU: 0.8, floorU: 1.2 };
    if (performance === '1984-1996')
      return { wt: 'double_glazing', ws: 0.1, roofU: 0.5, wallU: 0.5, floorU: 1.2 };
    if (performance === '1997-2008')
      return { wt: 'double_glazing', ws: 0.1, roofU: 0.36, wallU: 0.47, floorU: 0.47 };
    if (performance === '2009-2014')
      return { wt: 'double_glazing', ws: 0.1, roofU: 0.34, wallU: 0.45, floorU: 0.36 };
    if (performance === '2015-2018')
      return { wt: 'double_glazing', ws: 0.1, roofU: 0.3, wallU: 0.4, floorU: 0.3 };
    if (performance === 'E1')
      return { wt: 'double_glazing', ws: 0.1, roofU: 0.3, wallU: 0.4, floorU: 0.3 };
    if (performance === 'E2')
      return { wt: 'double_glazing', ws: 0.1, roofU: 0.3, wallU: 0.4, floorU: 0.3 };
    if (performance === 'E3')
      return { wt: 'double_glazing', ws: 0.1, roofU: 0.3, wallU: 0.4, floorU: 0.3 };
    if (performance === 'E4')
      return { wt: 'double_glazing', ws: 0.1, roofU: 0.3, wallU: 0.4, floorU: 0.3 };
    if (performance === 'UNDEFINED') return { wt: 'double_glazing', ws: 0.1, ...defaultU };
  }
  if (usageCode === 'B02') {
    if (performance === '0-1938')
      return { wt: 'single_glazing', ws: 0.15, roofU: 1.12, wallU: 3.09, floorU: 3.47 };
    if (performance === '1939-1968')
      return { wt: 'single_glazing', ws: 0.15, roofU: 1.01, wallU: 2.71, floorU: 2.78 };
    if (performance === '1969-1975')
      return { wt: 'single_glazing', ws: 0.15, roofU: 0.94, wallU: 1.88, floorU: 2.64 };
    if (performance === '1976-1983')
      return { wt: 'double_glazing', ws: 0.15, roofU: 0.57, wallU: 1.05, floorU: 0.77 };
    if (performance === '1984-1996')
      return { wt: 'double_glazing', ws: 0.15, roofU: 0.46, wallU: 0.54, floorU: 0.77 };
    if (performance === '1997-2008')
      return { wt: 'double_glazing', ws: 0.15, roofU: 0.39, wallU: 0.51, floorU: 0.51 };
    if (performance === '2009-2014')
      return { wt: 'double_glazing', ws: 0.15, roofU: 0.37, wallU: 0.48, floorU: 0.39 };
    if (performance === '2015-2018')
      return { wt: 'double_glazing', ws: 0.15, roofU: 0.32, wallU: 0.43, floorU: 0.32 };
    if (performance === 'E1')
      return { wt: 'double_glazing', ws: 0.15, roofU: 0.32, wallU: 0.43, floorU: 0.32 };
    if (performance === 'E2')
      return { wt: 'double_glazing', ws: 0.15, roofU: 0.32, wallU: 0.43, floorU: 0.32 };
    if (performance === 'E3')
      return { wt: 'double_glazing', ws: 0.15, roofU: 0.32, wallU: 0.43, floorU: 0.32 };
    if (performance === 'E4')
      return { wt: 'double_glazing', ws: 0.15, roofU: 0.32, wallU: 0.43, floorU: 0.32 };
    if (performance === 'UNDEFINED') return { wt: 'double_glazing', ws: 0.15, ...defaultU };
  }
  if (usageCode === 'B03') {
    if (performance === '0-1938')
      return { wt: 'single_glazing', ws: 0.2, roofU: 1.0, wallU: 2.75, floorU: 3.0 };
    if (performance === '1939-1968')
      return { wt: 'single_glazing', ws: 0.2, roofU: 1.0, wallU: 2.75, floorU: 3.0 };
    if (performance === '1969-1975')
      return { wt: 'single_glazing', ws: 0.2, roofU: 1.0, wallU: 2.75, floorU: 3.1 };
    if (performance === '1976-1983')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.5, wallU: 1.05, floorU: 0.5 };
    if (performance === '1984-1996')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.4, wallU: 0.5, floorU: 0.5 };
    if (performance === '1997-2008')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.36, wallU: 0.47, floorU: 0.47 };
    if (performance === '2009-2014')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.34, wallU: 0.45, floorU: 0.36 };
    if (performance === '2015-2018')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.3, wallU: 0.4, floorU: 0.3 };
    if (performance === 'E1')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.3, wallU: 0.4, floorU: 0.3 };
    if (performance === 'E2')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.3, wallU: 0.4, floorU: 0.3 };
    if (performance === 'E3')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.3, wallU: 0.4, floorU: 0.3 };
    if (performance === 'E4')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.3, wallU: 0.4, floorU: 0.3 };
    if (performance === 'UNDEFINED') return { wt: 'double_glazing', ws: 0.2, ...defaultU };
  }
  if (usageCode === 'B04') {
    if (performance === '0-1938')
      return { wt: 'single_glazing', ws: 0.33, roofU: 1.15, wallU: 3.16, floorU: 3.53 };
    if (performance === '1939-1968')
      return { wt: 'single_glazing', ws: 0.33, roofU: 1.04, wallU: 2.83, floorU: 3.02 };
    if (performance === '1969-1975')
      return { wt: 'single_glazing', ws: 0.33, roofU: 1.0, wallU: 2.62, floorU: 2.94 };
    if (performance === '1976-1983')
      return { wt: 'double_glazing', ws: 0.33, roofU: 0.56, wallU: 2.06, floorU: 0.66 };
    if (performance === '1984-1996')
      return { wt: 'double_glazing', ws: 0.33, roofU: 0.45, wallU: 0.85, floorU: 0.66 };
    if (performance === '1997-2008')
      return { wt: 'double_glazing', ws: 0.33, roofU: 0.39, wallU: 0.52, floorU: 0.51 };
    if (performance === '2009-2014')
      return { wt: 'double_glazing', ws: 0.33, roofU: 0.37, wallU: 0.5, floorU: 0.39 };
    if (performance === '2015-2018')
      return { wt: 'double_glazing', ws: 0.33, roofU: 0.32, wallU: 0.46, floorU: 0.32 };
    if (performance === 'E1')
      return { wt: 'double_glazing', ws: 0.33, roofU: 0.32, wallU: 0.46, floorU: 0.32 };
    if (performance === 'E2')
      return { wt: 'double_glazing', ws: 0.33, roofU: 0.32, wallU: 0.46, floorU: 0.32 };
    if (performance === 'E3')
      return { wt: 'double_glazing', ws: 0.33, roofU: 0.32, wallU: 0.46, floorU: 0.32 };
    if (performance === 'E4')
      return { wt: 'double_glazing', ws: 0.33, roofU: 0.32, wallU: 0.46, floorU: 0.32 };
    if (performance === 'UNDEFINED') return { wt: 'double_glazing', ws: 0.33, ...defaultU };
  }
  if (usageCode === 'B05') {
    if (performance === '0-1938')
      return { wt: 'single_glazing', ws: 0.3, roofU: 1.93, wallU: 2.5, floorU: 2.3 };
    if (performance === '1939-1968')
      return { wt: 'single_glazing', ws: 0.33, roofU: 1.45, wallU: 2.4, floorU: 2.16 };
    if (performance === '1969-1975')
      return { wt: 'single_glazing', ws: 0.33, roofU: 0.91, wallU: 1.58, floorU: 2.11 };
    if (performance === '1976-1983')
      return { wt: 'single_glazing', ws: 0.37, roofU: 0.87, wallU: 1.15, floorU: 1.46 };
    if (performance === '1984-1996')
      return { wt: 'double_glazing', ws: 0.37, roofU: 0.7, wallU: 0.94, floorU: 1.0 };
    if (performance === '1997-2008')
      return { wt: 'double_glazing', ws: 0.37, roofU: 0.53, wallU: 0.81, floorU: 0.7 };
    if (performance === '2009-2014')
      return { wt: 'double_glazing', ws: 0.37, roofU: 0.34, wallU: 0.46, floorU: 0.38 };
    if (performance === '2015-2018')
      return { wt: 'double_glazing', ws: 0.37, roofU: 0.24, wallU: 0.37, floorU: 0.26 };
    if (performance === 'E1')
      return { wt: 'double_glazing', ws: 0.37, roofU: 0.24, wallU: 0.37, floorU: 0.26 };
    if (performance === 'E2')
      return { wt: 'double_glazing', ws: 0.37, roofU: 0.24, wallU: 0.37, floorU: 0.26 };
    if (performance === 'E3')
      return { wt: 'double_glazing', ws: 0.37, roofU: 0.24, wallU: 0.37, floorU: 0.26 };
    if (performance === 'E4')
      return { wt: 'double_glazing', ws: 0.37, roofU: 0.24, wallU: 0.37, floorU: 0.26 };
    if (performance === 'UNDEFINED') return { wt: 'double_glazing', ws: 0.33, ...defaultU };
  }
  if (usageCode === 'B06') {
    if (performance === '0-1938')
      return { wt: 'single_glazing', ws: 0.3, roofU: 1.8, wallU: 2.5, floorU: 2.3 };
    if (performance === '1939-1968')
      return { wt: 'single_glazing', ws: 0.32, roofU: 1.48, wallU: 2.4, floorU: 2.16 };
    if (performance === '1969-1975')
      return { wt: 'single_glazing', ws: 0.32, roofU: 0.97, wallU: 1.58, floorU: 2.11 };
    if (performance === '1976-1983')
      return { wt: 'single_glazing', ws: 0.37, roofU: 0.85, wallU: 1.15, floorU: 1.46 };
    if (performance === '1984-1996')
      return { wt: 'double_glazing', ws: 0.37, roofU: 0.69, wallU: 0.94, floorU: 1.0 };
    if (performance === '1997-2008')
      return { wt: 'double_glazing', ws: 0.37, roofU: 0.53, wallU: 0.81, floorU: 0.7 };
    if (performance === '2009-2014')
      return { wt: 'double_glazing', ws: 0.37, roofU: 0.34, wallU: 0.46, floorU: 0.38 };
    if (performance === '2015-2018')
      return { wt: 'double_glazing', ws: 0.37, roofU: 0.24, wallU: 0.37, floorU: 0.26 };
    if (performance === 'E1')
      return { wt: 'double_glazing', ws: 0.37, roofU: 0.27, wallU: 0.35, floorU: 0.5 };
    if (performance === 'E2')
      return { wt: 'double_glazing', ws: 0.37, roofU: 0.27, wallU: 0.35, floorU: 0.5 };
    if (performance === 'E3')
      return { wt: 'double_glazing', ws: 0.37, roofU: 0.27, wallU: 0.35, floorU: 0.5 };
    if (performance === 'E4')
      return { wt: 'double_glazing', ws: 0.37, roofU: 0.27, wallU: 0.35, floorU: 0.5 };
    if (performance === 'UNDEFINED') return { wt: 'double_glazing', ws: 0.32, ...defaultU };
  }
  if (usageCode === 'B07') {
    if (performance === '0-1938')
      return { wt: 'single_glazing', ws: 0.15, roofU: 0.93, wallU: 2.0, floorU: 1.5 };
    if (performance === '1939-1968')
      return { wt: 'single_glazing', ws: 0.15, roofU: 0.93, wallU: 2.0, floorU: 1.5 };
    if (performance === '1969-1975')
      return { wt: 'double_glazing', ws: 0.15, roofU: 0.86, wallU: 0.8, floorU: 1.2 };
    if (performance === '1976-1983')
      return { wt: 'double_glazing', ws: 0.15, roofU: 0.54, wallU: 0.8, floorU: 1.2 };
    if (performance === '1984-1996')
      return { wt: 'double_glazing', ws: 0.15, roofU: 0.43, wallU: 0.5, floorU: 1.2 };
    if (performance === '1997-2008')
      return { wt: 'double_glazing', ws: 0.15, roofU: 0.36, wallU: 0.47, floorU: 0.47 };
    if (performance === '2009-2014')
      return { wt: 'double_glazing', ws: 0.15, roofU: 0.34, wallU: 0.45, floorU: 0.36 };
    if (performance === '2015-2018')
      return { wt: 'double_glazing', ws: 0.15, roofU: 0.3, wallU: 0.4, floorU: 0.3 };
    if (performance === 'E1')
      return { wt: 'double_glazing', ws: 0.15, roofU: 0.3, wallU: 0.4, floorU: 0.3 };
    if (performance === 'E2')
      return { wt: 'double_glazing', ws: 0.15, roofU: 0.3, wallU: 0.4, floorU: 0.3 };
    if (performance === 'E3')
      return { wt: 'double_glazing', ws: 0.15, roofU: 0.3, wallU: 0.4, floorU: 0.3 };
    if (performance === 'E4')
      return { wt: 'double_glazing', ws: 0.15, roofU: 0.3, wallU: 0.4, floorU: 0.3 };
    if (performance === 'UNDEFINED') return { wt: 'double_glazing', ws: 0.15, ...defaultU };
  }
  if (usageCode === 'B08') {
    if (performance === '0-1938')
      return { wt: 'single_glazing', ws: 0.2, roofU: 0.93, wallU: 2.0, floorU: 1.5 };
    if (performance === '1939-1968')
      return { wt: 'single_glazing', ws: 0.2, roofU: 0.93, wallU: 2.0, floorU: 1.5 };
    if (performance === '1969-1975')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.86, wallU: 0.8, floorU: 1.2 };
    if (performance === '1976-1983')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.54, wallU: 0.8, floorU: 1.2 };
    if (performance === '1984-1996')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.43, wallU: 0.5, floorU: 1.2 };
    if (performance === '1997-2008')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.36, wallU: 0.47, floorU: 0.47 };
    if (performance === '2009-2014')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.34, wallU: 0.45, floorU: 0.36 };
    if (performance === '2015-2018')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.3, wallU: 0.4, floorU: 0.3 };
    if (performance === 'E1')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.3, wallU: 0.4, floorU: 0.3 };
    if (performance === 'E2')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.3, wallU: 0.4, floorU: 0.3 };
    if (performance === 'E3')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.3, wallU: 0.4, floorU: 0.3 };
    if (performance === 'E4')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.3, wallU: 0.4, floorU: 0.3 };
    if (performance === 'UNDEFINED') return { wt: 'double_glazing', ws: 0.2, ...defaultU };
  }
  if (usageCode === 'B09') {
    if (performance === '0-1938')
      return { wt: 'single_glazing', ws: 0.4, roofU: 1.11, wallU: 3.06, floorU: 3.42 };
    if (performance === '1939-1968')
      return { wt: 'single_glazing', ws: 0.4, roofU: 1.02, wallU: 2.77, floorU: 2.91 };
    if (performance === '1969-1975')
      return { wt: 'single_glazing', ws: 0.4, roofU: 0.97, wallU: 2.47, floorU: 2.8 };
    if (performance === '1976-1983')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.56, wallU: 2.04, floorU: 0.71 };
    if (performance === '1984-1996')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.46, wallU: 0.85, floorU: 0.71 };
    if (performance === '1997-2008')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.39, wallU: 0.52, floorU: 0.51 };
    if (performance === '2009-2014')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.37, wallU: 0.5, floorU: 0.39 };
    if (performance === '2015-2018')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.32, wallU: 0.46, floorU: 0.32 };
    if (performance === 'E1')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.32, wallU: 0.46, floorU: 0.32 };
    if (performance === 'E2')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.32, wallU: 0.46, floorU: 0.32 };
    if (performance === 'E3')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.32, wallU: 0.46, floorU: 0.32 };
    if (performance === 'E4')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.32, wallU: 0.46, floorU: 0.32 };
    if (performance === 'UNDEFINED') return { wt: 'double_glazing', ws: 0.4, ...defaultU };
  }
  if (usageCode === 'B10') {
    if (performance === '0-1938')
      return { wt: 'single_glazing', ws: 0.4, roofU: 1.11, wallU: 3.06, floorU: 3.42 };
    if (performance === '1939-1968')
      return { wt: 'single_glazing', ws: 0.4, roofU: 1.02, wallU: 2.77, floorU: 2.91 };
    if (performance === '1969-1975')
      return { wt: 'single_glazing', ws: 0.4, roofU: 0.97, wallU: 2.47, floorU: 2.8 };
    if (performance === '1976-1983')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.56, wallU: 2.04, floorU: 0.71 };
    if (performance === '1984-1996')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.46, wallU: 0.85, floorU: 0.71 };
    if (performance === '1997-2008')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.39, wallU: 0.52, floorU: 0.51 };
    if (performance === '2009-2014')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.37, wallU: 0.5, floorU: 0.39 };
    if (performance === '2015-2018')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.32, wallU: 0.46, floorU: 0.32 };
    if (performance === 'E1')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.32, wallU: 0.46, floorU: 0.32 };
    if (performance === 'E2')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.32, wallU: 0.46, floorU: 0.32 };
    if (performance === 'E3')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.32, wallU: 0.46, floorU: 0.32 };
    if (performance === 'E4')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.32, wallU: 0.46, floorU: 0.32 };
    if (performance === 'UNDEFINED') return { wt: 'double_glazing', ws: 0.4, ...defaultU };
  }
  if (usageCode === 'B11') {
    if (performance === '0-1938')
      return { wt: 'single_glazing', ws: 0.3, roofU: 3.16, wallU: 3.27, floorU: 1.48 };
    if (performance === '1939-1968')
      return { wt: 'single_glazing', ws: 0.3, roofU: 2.14, wallU: 2.91, floorU: 1.61 };
    if (performance === '1969-1975')
      return { wt: 'single_glazing', ws: 0.3, roofU: 2.06, wallU: 2.5, floorU: 1.56 };
    if (performance === '1976-1983')
      return { wt: 'single_glazing', ws: 0.3, roofU: 0.55, wallU: 2.07, floorU: 0.91 };
    if (performance === '1984-1996')
      return { wt: 'single_glazing', ws: 0.3, roofU: 0.5, wallU: 0.54, floorU: 0.91 };
    if (performance === '1997-2008')
      return { wt: 'double_glazing', ws: 0.3, roofU: 0.36, wallU: 0.51, floorU: 0.47 };
    if (performance === '2009-2014')
      return { wt: 'double_glazing', ws: 0.3, roofU: 0.35, wallU: 0.47, floorU: 0.41 };
    if (performance === '2015-2018')
      return { wt: 'double_glazing', ws: 0.3, roofU: 0.22, wallU: 0.33, floorU: 0.27 };
    if (performance === 'E1')
      return { wt: 'double_glazing', ws: 0.3, roofU: 0.3, wallU: 0.36, floorU: 0.25 };
    if (performance === 'E2')
      return { wt: 'double_glazing', ws: 0.3, roofU: 0.3, wallU: 0.36, floorU: 0.25 };
    if (performance === 'E3')
      return { wt: 'double_glazing', ws: 0.3, roofU: 0.15, wallU: 0.2, floorU: 0.12 };
    if (performance === 'E4')
      return { wt: 'double_glazing', ws: 0.3, roofU: 0.15, wallU: 0.2, floorU: 0.12 };
    if (performance === 'UNDEFINED') return { wt: 'double_glazing', ws: 0.3, ...defaultU };
  }
  if (usageCode === 'B12') {
    if (performance === '0-1938')
      return { wt: 'single_glazing', ws: 0.15, roofU: 2.07, wallU: 2.42, floorU: 2.07 };
    if (performance === '1939-1968')
      return { wt: 'single_glazing', ws: 0.15, roofU: 1.44, wallU: 2.21, floorU: 1.79 };
    if (performance === '1969-1975')
      return { wt: 'single_glazing', ws: 0.15, roofU: 0.88, wallU: 1.09, floorU: 1.64 };
    if (performance === '1976-1983')
      return { wt: 'single_glazing', ws: 0.15, roofU: 0.88, wallU: 1.09, floorU: 1.64 };
    if (performance === '1984-1996')
      return { wt: 'double_glazing', ws: 0.15, roofU: 0.71, wallU: 0.94, floorU: 1.18 };
    if (performance === '1997-2008')
      return { wt: 'double_glazing', ws: 0.15, roofU: 0.53, wallU: 0.81, floorU: 0.7 };
    if (performance === '2009-2014')
      return { wt: 'double_glazing', ws: 0.15, roofU: 0.34, wallU: 0.46, floorU: 0.38 };
    if (performance === '2015-2018')
      return { wt: 'double_glazing', ws: 0.15, roofU: 0.24, wallU: 0.37, floorU: 0.26 };
    if (performance === 'E1')
      return { wt: 'double_glazing', ws: 0.15, roofU: 0.24, wallU: 0.37, floorU: 0.26 };
    if (performance === 'E2')
      return { wt: 'double_glazing', ws: 0.15, roofU: 0.24, wallU: 0.37, floorU: 0.26 };
    if (performance === 'E3')
      return { wt: 'double_glazing', ws: 0.15, roofU: 0.24, wallU: 0.37, floorU: 0.26 };
    if (performance === 'E4')
      return { wt: 'double_glazing', ws: 0.15, roofU: 0.24, wallU: 0.37, floorU: 0.26 };
    if (performance === 'UNDEFINED') return { wt: 'double_glazing', ws: 0.15, ...defaultU };
  }
  if (usageCode === 'B13') {
    if (performance === '0-1938')
      return { wt: 'single_glazing', ws: 0.4, roofU: 2.27, wallU: 2.94, floorU: 3.11 };
    if (performance === '1939-1968')
      return { wt: 'single_glazing', ws: 0.4, roofU: 1.8, wallU: 2.69, floorU: 2.7 };
    if (performance === '1969-1975')
      return { wt: 'single_glazing', ws: 0.4, roofU: 1.21, wallU: 2.29, floorU: 2.61 };
    if (performance === '1976-1983')
      return { wt: 'single_glazing', ws: 0.4, roofU: 0.54, wallU: 1.86, floorU: 1.13 };
    if (performance === '1984-1996')
      return { wt: 'single_glazing', ws: 0.4, roofU: 0.43, wallU: 0.53, floorU: 0.69 };
    if (performance === '1997-2008')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.32, wallU: 0.46, floorU: 0.42 };
    if (performance === '2009-2014')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.31, wallU: 0.47, floorU: 0.39 };
    if (performance === '2015-2018')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.22, wallU: 0.33, floorU: 0.25 };
    if (performance === 'E1')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.3, wallU: 0.36, floorU: 0.25 };
    if (performance === 'E2')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.3, wallU: 0.36, floorU: 0.25 };
    if (performance === 'E3')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.15, wallU: 0.2, floorU: 0.12 };
    if (performance === 'E4')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.15, wallU: 0.2, floorU: 0.12 };
    if (performance === 'UNDEFINED') return { wt: 'double_glazing', ws: 0.4, ...defaultU };
  }
  if (usageCode === 'B14') {
    if (performance === '0-1938')
      return { wt: 'single_glazing', ws: 0.42, roofU: 2.27, wallU: 3.0, floorU: 3.15 };
    if (performance === '1939-1968')
      return { wt: 'single_glazing', ws: 0.42, roofU: 1.81, wallU: 2.5, floorU: 2.33 };
    if (performance === '1969-1975')
      return { wt: 'single_glazing', ws: 0.42, roofU: 1.24, wallU: 1.8, floorU: 2.13 };
    if (performance === '1976-1983')
      return { wt: 'single_glazing', ws: 0.42, roofU: 0.53, wallU: 1.8, floorU: 1.31 };
    if (performance === '1984-1996')
      return { wt: 'single_glazing', ws: 0.42, roofU: 0.42, wallU: 0.53, floorU: 0.87 };
    if (performance === '1997-2008')
      return { wt: 'double_glazing', ws: 0.42, roofU: 0.32, wallU: 0.46, floorU: 0.42 };
    if (performance === '2009-2014')
      return { wt: 'double_glazing', ws: 0.42, roofU: 0.31, wallU: 0.47, floorU: 0.39 };
    if (performance === '2015-2018')
      return { wt: 'double_glazing', ws: 0.42, roofU: 0.22, wallU: 0.33, floorU: 0.24 };
    if (performance === 'E1')
      return { wt: 'double_glazing', ws: 0.42, roofU: 0.3, wallU: 0.36, floorU: 0.25 };
    if (performance === 'E2')
      return { wt: 'double_glazing', ws: 0.42, roofU: 0.3, wallU: 0.36, floorU: 0.25 };
    if (performance === 'E3')
      return { wt: 'double_glazing', ws: 0.42, roofU: 0.15, wallU: 0.2, floorU: 0.12 };
    if (performance === 'E4')
      return { wt: 'double_glazing', ws: 0.42, roofU: 0.15, wallU: 0.2, floorU: 0.12 };
    if (performance === 'UNDEFINED') return { wt: 'double_glazing', ws: 0.42, ...defaultU };
  }
  if (usageCode === 'B15') {
    if (performance === '0-1938')
      return { wt: 'single_glazing', ws: 0.45, roofU: 2.27, wallU: 2.88, floorU: 3.08 };
    if (performance === '1939-1968')
      return { wt: 'single_glazing', ws: 0.45, roofU: 1.79, wallU: 2.88, floorU: 3.08 };
    if (performance === '1969-1975')
      return { wt: 'single_glazing', ws: 0.45, roofU: 1.18, wallU: 2.77, floorU: 3.08 };
    if (performance === '1976-1983')
      return { wt: 'single_glazing', ws: 0.45, roofU: 0.55, wallU: 1.93, floorU: 0.96 };
    if (performance === '1984-1996')
      return { wt: 'single_glazing', ws: 0.45, roofU: 0.43, wallU: 0.53, floorU: 0.52 };
    if (performance === '1997-2008')
      return { wt: 'double_glazing', ws: 0.45, roofU: 0.32, wallU: 0.46, floorU: 0.42 };
    if (performance === '2009-2014')
      return { wt: 'double_glazing', ws: 0.45, roofU: 0.31, wallU: 0.47, floorU: 0.39 };
    if (performance === '2015-2018')
      return { wt: 'double_glazing', ws: 0.45, roofU: 0.22, wallU: 0.33, floorU: 0.24 };
    if (performance === 'E1')
      return { wt: 'double_glazing', ws: 0.45, roofU: 0.3, wallU: 0.36, floorU: 0.25 };
    if (performance === 'E2')
      return { wt: 'double_glazing', ws: 0.45, roofU: 0.3, wallU: 0.36, floorU: 0.25 };
    if (performance === 'E3')
      return { wt: 'double_glazing', ws: 0.45, roofU: 0.15, wallU: 0.2, floorU: 0.12 };
    if (performance === 'E4')
      return { wt: 'double_glazing', ws: 0.45, roofU: 0.15, wallU: 0.2, floorU: 0.12 };
    if (performance === 'UNDEFINED') return { wt: 'double_glazing', ws: 0.45, ...defaultU };
  }
  if (usageCode === 'B16') {
    if (performance === '0-1938')
      return { wt: 'single_glazing', ws: 0.05, roofU: 1.15, wallU: 2.3, floorU: 3.45 };
    if (performance === '1939-1968')
      return { wt: 'single_glazing', ws: 0.05, roofU: 1.04, wallU: 2.15, floorU: 2.48 };
    if (performance === '1969-1975')
      return { wt: 'double_glazing', ws: 0.05, roofU: 0.72, wallU: 1.55, floorU: 0.89 };
    if (performance === '1976-1983')
      return { wt: 'double_glazing', ws: 0.05, roofU: 0.56, wallU: 1.55, floorU: 0.89 };
    if (performance === '1984-1996')
      return { wt: 'double_glazing', ws: 0.05, roofU: 0.45, wallU: 0.71, floorU: 0.89 };
    if (performance === '1997-2008')
      return { wt: 'double_glazing', ws: 0.05, roofU: 0.39, wallU: 0.52, floorU: 0.51 };
    if (performance === '2009-2014')
      return { wt: 'double_glazing', ws: 0.05, roofU: 0.37, wallU: 0.48, floorU: 0.39 };
    if (performance === '2015-2018')
      return { wt: 'double_glazing', ws: 0.05, roofU: 0.32, wallU: 0.42, floorU: 0.32 };
    if (performance === 'E1')
      return { wt: 'double_glazing', ws: 0.05, roofU: 0.32, wallU: 0.42, floorU: 0.32 };
    if (performance === 'E2')
      return { wt: 'double_glazing', ws: 0.05, roofU: 0.32, wallU: 0.42, floorU: 0.32 };
    if (performance === 'E3')
      return { wt: 'double_glazing', ws: 0.05, roofU: 0.32, wallU: 0.42, floorU: 0.32 };
    if (performance === 'E4')
      return { wt: 'double_glazing', ws: 0.05, roofU: 0.32, wallU: 0.42, floorU: 0.32 };
    if (performance === 'UNDEFINED') return { wt: 'double_glazing', ws: 0.05, ...defaultU };
  }
  if (usageCode === 'B18') {
    if (performance === '0-1938')
      return { wt: 'single_glazing', ws: 0.35, roofU: 0.92, wallU: 2.3, floorU: 1.73 };
    if (performance === '1939-1968')
      return { wt: 'single_glazing', ws: 0.35, roofU: 0.92, wallU: 2.3, floorU: 1.73 };
    if (performance === '1969-1975')
      return { wt: 'double_glazing', ws: 0.35, roofU: 0.69, wallU: 1.91, floorU: 1.38 };
    if (performance === '1976-1983')
      return { wt: 'double_glazing', ws: 0.35, roofU: 0.69, wallU: 1.91, floorU: 1.38 };
    if (performance === '1984-1996')
      return { wt: 'double_glazing', ws: 0.35, roofU: 0.58, wallU: 0.82, floorU: 1.38 };
    if (performance === '1997-2008')
      return { wt: 'double_glazing', ws: 0.35, roofU: 0.39, wallU: 0.52, floorU: 0.51 };
    if (performance === '2009-2014')
      return { wt: 'double_glazing', ws: 0.35, roofU: 0.37, wallU: 0.49, floorU: 0.39 };
    if (performance === '2015-2018')
      return { wt: 'double_glazing', ws: 0.35, roofU: 0.32, wallU: 0.42, floorU: 0.32 };
    if (performance === 'E1')
      return { wt: 'double_glazing', ws: 0.35, roofU: 0.32, wallU: 0.42, floorU: 0.32 };
    if (performance === 'E2')
      return { wt: 'double_glazing', ws: 0.35, roofU: 0.32, wallU: 0.42, floorU: 0.32 };
    if (performance === 'E3')
      return { wt: 'double_glazing', ws: 0.35, roofU: 0.32, wallU: 0.42, floorU: 0.32 };
    if (performance === 'E4')
      return { wt: 'double_glazing', ws: 0.35, roofU: 0.32, wallU: 0.42, floorU: 0.32 };
    if (performance === 'UNDEFINED') return { wt: 'double_glazing', ws: 0.35, ...defaultU };
  }
  if (usageCode === 'B19') {
    if (performance === '0-1938')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.68, wallU: 1.23, floorU: 1.17 };
    if (performance === '1939-1968')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.68, wallU: 1.23, floorU: 1.17 };
    if (performance === '1969-1975')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.68, wallU: 1.23, floorU: 1.17 };
    if (performance === '1976-1983')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.52, wallU: 1.23, floorU: 1.17 };
    if (performance === '1984-1996')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.48, wallU: 1.23, floorU: 1.17 };
    if (performance === '1997-2008')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.43, wallU: 0.85, floorU: 0.82 };
    if (performance === '2009-2014')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.42, wallU: 0.84, floorU: 0.77 };
    if (performance === '2015-2018')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.4, wallU: 0.82, floorU: 0.74 };
    if (performance === 'E1')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.4, wallU: 0.82, floorU: 0.74 };
    if (performance === 'E2')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.4, wallU: 0.82, floorU: 0.74 };
    if (performance === 'E3')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.4, wallU: 0.82, floorU: 0.74 };
    if (performance === 'E4')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.4, wallU: 0.82, floorU: 0.74 };
    if (performance === 'UNDEFINED') return { wt: 'double_glazing', ws: 0.4, ...defaultU };
  }
  if (usageCode === 'B20') {
    if (performance === '0-1938')
      return { wt: 'single_glazing', ws: 0.05, roofU: 1.02, wallU: 2.3, floorU: 1.73 };
    if (performance === '1939-1968')
      return { wt: 'single_glazing', ws: 0.05, roofU: 0.91, wallU: 2.15, floorU: 1.61 };
    if (performance === '1969-1975')
      return { wt: 'double_glazing', ws: 0.05, roofU: 0.62, wallU: 1.55, floorU: 1.29 };
    if (performance === '1976-1983')
      return { wt: 'double_glazing', ws: 0.05, roofU: 0.62, wallU: 1.55, floorU: 1.29 };
    if (performance === '1984-1996')
      return { wt: 'double_glazing', ws: 0.05, roofU: 0.51, wallU: 0.71, floorU: 1.29 };
    if (performance === '1997-2008')
      return { wt: 'double_glazing', ws: 0.05, roofU: 0.39, wallU: 0.52, floorU: 0.51 };
    if (performance === '2009-2014')
      return { wt: 'double_glazing', ws: 0.05, roofU: 0.37, wallU: 0.48, floorU: 0.39 };
    if (performance === '2015-2018')
      return { wt: 'double_glazing', ws: 0.05, roofU: 0.37, wallU: 0.42, floorU: 0.27 };
    if (performance === 'E1')
      return { wt: 'double_glazing', ws: 0.05, roofU: 0.37, wallU: 0.42, floorU: 0.27 };
    if (performance === 'E2')
      return { wt: 'double_glazing', ws: 0.05, roofU: 0.37, wallU: 0.42, floorU: 0.27 };
    if (performance === 'E3')
      return { wt: 'double_glazing', ws: 0.05, roofU: 0.37, wallU: 0.42, floorU: 0.27 };
    if (performance === 'E4')
      return { wt: 'double_glazing', ws: 0.05, roofU: 0.37, wallU: 0.42, floorU: 0.27 };
    if (performance === 'UNDEFINED') return { wt: 'double_glazing', ws: 0.05, ...defaultU };
  }
  if (usageCode === 'B21') {
    if (performance === '0-1938')
      return { wt: 'single_glazing', ws: 0.05, roofU: 1.08, wallU: 2.3, floorU: 1.73 };
    if (performance === '1939-1968')
      return { wt: 'single_glazing', ws: 0.05, roofU: 0.97, wallU: 2.15, floorU: 1.61 };
    if (performance === '1969-1975')
      return { wt: 'double_glazing', ws: 0.05, roofU: 0.75, wallU: 1.55, floorU: 1.29 };
    if (performance === '1976-1983')
      return { wt: 'double_glazing', ws: 0.05, roofU: 0.59, wallU: 1.55, floorU: 1.29 };
    if (performance === '1984-1996')
      return { wt: 'double_glazing', ws: 0.05, roofU: 0.48, wallU: 0.71, floorU: 1.29 };
    if (performance === '1997-2008')
      return { wt: 'double_glazing', ws: 0.05, roofU: 0.39, wallU: 0.52, floorU: 0.51 };
    if (performance === '2009-2014')
      return { wt: 'double_glazing', ws: 0.05, roofU: 0.37, wallU: 0.48, floorU: 0.39 };
    if (performance === '2015-2018')
      return { wt: 'double_glazing', ws: 0.05, roofU: 0.37, wallU: 0.42, floorU: 0.27 };
    if (performance === 'E1')
      return { wt: 'double_glazing', ws: 0.05, roofU: 0.37, wallU: 0.42, floorU: 0.27 };
    if (performance === 'E2')
      return { wt: 'double_glazing', ws: 0.05, roofU: 0.37, wallU: 0.42, floorU: 0.27 };
    if (performance === 'E3')
      return { wt: 'double_glazing', ws: 0.05, roofU: 0.37, wallU: 0.42, floorU: 0.27 };
    if (performance === 'E4')
      return { wt: 'double_glazing', ws: 0.05, roofU: 0.37, wallU: 0.42, floorU: 0.27 };
    if (performance === 'UNDEFINED') return { wt: 'double_glazing', ws: 0.05, ...defaultU };
  }
  if (usageCode === 'B22') {
    if (performance === '0-1938')
      return { wt: 'single_glazing', ws: 0.3, roofU: 3.06, wallU: 2.98, floorU: 1.99 };
    if (performance === '1939-1968')
      return { wt: 'single_glazing', ws: 0.3, roofU: 0.69, wallU: 2.98, floorU: 1.99 };
    if (performance === '1969-1975')
      return { wt: 'single_glazing', ws: 0.3, roofU: 0.63, wallU: 2.67, floorU: 2.04 };
    if (performance === '1976-1983')
      return { wt: 'double_glazing', ws: 0.3, roofU: 0.53, wallU: 1.68, floorU: 0.43 };
    if (performance === '1984-1996')
      return { wt: 'single_glazing', ws: 0.3, roofU: 0.48, wallU: 0.71, floorU: 0.43 };
    if (performance === '1997-2008')
      return { wt: 'double_glazing', ws: 0.3, roofU: 0.43, wallU: 0.55, floorU: 0.41 };
    if (performance === '2009-2014')
      return { wt: 'double_glazing', ws: 0.3, roofU: 0.42, wallU: 0.48, floorU: 0.36 };
    if (performance === '2015-2018')
      return { wt: 'double_glazing', ws: 0.3, roofU: 0.4, wallU: 0.42, floorU: 0.33 };
    if (performance === 'E1')
      return { wt: 'double_glazing', ws: 0.3, roofU: 0.4, wallU: 0.42, floorU: 0.33 };
    if (performance === 'E2')
      return { wt: 'double_glazing', ws: 0.3, roofU: 0.4, wallU: 0.42, floorU: 0.33 };
    if (performance === 'E3')
      return { wt: 'double_glazing', ws: 0.3, roofU: 0.4, wallU: 0.42, floorU: 0.33 };
    if (performance === 'E4')
      return { wt: 'double_glazing', ws: 0.3, roofU: 0.4, wallU: 0.42, floorU: 0.33 };
    if (performance === 'UNDEFINED') return { wt: 'double_glazing', ws: 0.3, ...defaultU };
  }
  if (usageCode === 'B23') {
    if (performance === '0-1938')
      return { wt: 'single_glazing', ws: 0.28, roofU: 0.91, wallU: 2.41, floorU: 2.04 };
    if (performance === '1939-1968')
      return { wt: 'single_glazing', ws: 0.34, roofU: 0.88, wallU: 2.34, floorU: 1.99 };
    if (performance === '1969-1975')
      return { wt: 'double_glazing', ws: 0.34, roofU: 0.68, wallU: 2.04, floorU: 1.77 };
    if (performance === '1976-1983')
      return { wt: 'double_glazing', ws: 0.45, roofU: 0.64, wallU: 1.61, floorU: 1.11 };
    if (performance === '1984-1996')
      return { wt: 'double_glazing', ws: 0.45, roofU: 0.53, wallU: 0.71, floorU: 1.11 };
    if (performance === '1997-2008')
      return { wt: 'double_glazing', ws: 0.45, roofU: 0.39, wallU: 0.52, floorU: 0.51 };
    if (performance === '2009-2014')
      return { wt: 'double_glazing', ws: 0.45, roofU: 0.37, wallU: 0.49, floorU: 0.39 };
    if (performance === '2015-2018')
      return { wt: 'double_glazing', ws: 0.45, roofU: 0.26, wallU: 0.33, floorU: 0.3 };
    if (performance === 'E1')
      return { wt: 'double_glazing', ws: 0.45, roofU: 0.26, wallU: 0.33, floorU: 0.3 };
    if (performance === 'E2')
      return { wt: 'double_glazing', ws: 0.45, roofU: 0.26, wallU: 0.33, floorU: 0.3 };
    if (performance === 'E3')
      return { wt: 'double_glazing', ws: 0.45, roofU: 0.26, wallU: 0.33, floorU: 0.3 };
    if (performance === 'E4')
      return { wt: 'double_glazing', ws: 0.45, roofU: 0.26, wallU: 0.33, floorU: 0.3 };
    if (performance === 'UNDEFINED') return { wt: 'double_glazing', ws: 0.34, ...defaultU };
  }
  if (usageCode === 'B25') {
    if (performance === '0-1938')
      return { wt: 'single_glazing', ws: 0.2, roofU: 2.38, wallU: 2.36, floorU: 1.81 };
    if (performance === '1939-1968')
      return { wt: 'single_glazing', ws: 0.2, roofU: 2.29, wallU: 2.36, floorU: 1.81 };
    if (performance === '1969-1975')
      return { wt: 'single_glazing', ws: 0.2, roofU: 1.11, wallU: 1.56, floorU: 1.17 };
    if (performance === '1976-1983')
      return { wt: 'single_glazing', ws: 0.2, roofU: 1.11, wallU: 1.09, floorU: 0.81 };
    if (performance === '1984-1996')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.69, wallU: 0.77, floorU: 0.69 };
    if (performance === '1997-2008')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.52, wallU: 0.62, floorU: 0.54 };
    if (performance === '2009-2014')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.28, wallU: 0.44, floorU: 0.42 };
    if (performance === '2015-2018')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.27, wallU: 0.33, floorU: 0.3 };
    if (performance === 'E1')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.17, wallU: 0.47, floorU: 0.4 };
    if (performance === 'E2')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.13, wallU: 0.32, floorU: 0.2 };
    if (performance === 'E3')
      return { wt: 'triple_glazing', ws: 0.2, roofU: 0.1, wallU: 0.25, floorU: 0.15 };
    if (performance === 'E4')
      return { wt: 'triple_glazing', ws: 0.2, roofU: 0.1, wallU: 0.25, floorU: 0.15 };
    if (performance === 'UNDEFINED') return { wt: 'double_glazing', ws: 0.2, ...defaultU };
  }
  if (usageCode === 'B26') {
    if (performance === '0-1938')
      return { wt: 'single_glazing', ws: 0.2, roofU: 1.89, wallU: 2.3, floorU: 1.69 };
    if (performance === '1939-1968')
      return { wt: 'single_glazing', ws: 0.2, roofU: 1.83, wallU: 2.3, floorU: 1.69 };
    if (performance === '1969-1975')
      return { wt: 'single_glazing', ws: 0.2, roofU: 1.08, wallU: 1.5, floorU: 1.06 };
    if (performance === '1976-1983')
      return { wt: 'single_glazing', ws: 0.2, roofU: 0.92, wallU: 1.05, floorU: 0.79 };
    if (performance === '1984-1996')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.59, wallU: 0.71, floorU: 0.65 };
    if (performance === '1997-2008')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.52, wallU: 0.56, floorU: 0.51 };
    if (performance === '2009-2014')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.28, wallU: 0.44, floorU: 0.41 };
    if (performance === '2015-2018')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.26, wallU: 0.32, floorU: 0.28 };
    if (performance === 'E1')
      return { wt: 'triple_glazing', ws: 0.2, roofU: 0.15, wallU: 0.27, floorU: 0.4 };
    if (performance === 'E2')
      return { wt: 'triple_glazing', ws: 0.2, roofU: 0.15, wallU: 0.27, floorU: 0.4 };
    if (performance === 'E3')
      return { wt: 'triple_glazing', ws: 0.2, roofU: 0.1, wallU: 0.16, floorU: 0.15 };
    if (performance === 'E4')
      return { wt: 'triple_glazing', ws: 0.2, roofU: 0.1, wallU: 0.16, floorU: 0.15 };
    if (performance === 'UNDEFINED') return { wt: 'double_glazing', ws: 0.2, ...defaultU };
  }
  if (usageCode === 'B27') {
    if (performance === '0-1938')
      return { wt: 'single_glazing', ws: 0.4, roofU: 1.52, wallU: 2.96, floorU: 3.28 };
    if (performance === '1939-1968')
      return { wt: 'single_glazing', ws: 0.4, roofU: 1.6, wallU: 3.16, floorU: 3.56 };
    if (performance === '1969-1975')
      return { wt: 'single_glazing', ws: 0.4, roofU: 1.6, wallU: 2.3, floorU: 3.45 };
    if (performance === '1976-1983')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.54, wallU: 1.21, floorU: 0.58 };
    if (performance === '1984-1996')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.41, wallU: 0.58, floorU: 0.58 };
    if (performance === '1997-2008')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.35, wallU: 0.51, floorU: 0.51 };
    if (performance === '2009-2014')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.31, wallU: 0.48, floorU: 0.39 };
    if (performance === '2015-2018')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.32, wallU: 0.43, floorU: 0.32 };
    if (performance === 'E1')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.32, wallU: 0.43, floorU: 0.32 };
    if (performance === 'E2')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.32, wallU: 0.43, floorU: 0.32 };
    if (performance === 'E3')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.32, wallU: 0.43, floorU: 0.32 };
    if (performance === 'E4')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.32, wallU: 0.43, floorU: 0.32 };
    if (performance === 'UNDEFINED') return { wt: 'double_glazing', ws: 0.4, ...defaultU };
  }
  if (usageCode === 'B28') {
    if (performance === '0-1938')
      return { wt: 'single_glazing', ws: 0.3, roofU: 1.52, wallU: 2.75, floorU: 3.23 };
    if (performance === '1939-1968')
      return { wt: 'single_glazing', ws: 0.3, roofU: 1.52, wallU: 2.75, floorU: 3.23 };
    if (performance === '1969-1975')
      return { wt: 'single_glazing', ws: 0.3, roofU: 1.24, wallU: 2.38, floorU: 1.84 };
    if (performance === '1976-1983')
      return { wt: 'double_glazing', ws: 0.3, roofU: 0.53, wallU: 1.52, floorU: 0.54 };
    if (performance === '1984-1996')
      return { wt: 'double_glazing', ws: 0.3, roofU: 0.38, wallU: 0.78, floorU: 0.54 };
    if (performance === '1997-2008')
      return { wt: 'double_glazing', ws: 0.3, roofU: 0.35, wallU: 0.48, floorU: 0.51 };
    if (performance === '2009-2014')
      return { wt: 'double_glazing', ws: 0.3, roofU: 0.31, wallU: 0.45, floorU: 0.39 };
    if (performance === '2015-2018')
      return { wt: 'double_glazing', ws: 0.3, roofU: 0.32, wallU: 0.43, floorU: 0.32 };
    if (performance === 'E1')
      return { wt: 'double_glazing', ws: 0.3, roofU: 0.32, wallU: 0.43, floorU: 0.32 };
    if (performance === 'E2')
      return { wt: 'double_glazing', ws: 0.3, roofU: 0.32, wallU: 0.43, floorU: 0.32 };
    if (performance === 'E3')
      return { wt: 'double_glazing', ws: 0.3, roofU: 0.32, wallU: 0.43, floorU: 0.32 };
    if (performance === 'E4')
      return { wt: 'double_glazing', ws: 0.3, roofU: 0.32, wallU: 0.43, floorU: 0.32 };
    if (performance === 'UNDEFINED') return { wt: 'double_glazing', ws: 0.3, ...defaultU };
  }
  if (usageCode === 'B29') {
    if (performance === '0-1938')
      return { wt: 'single_glazing', ws: 0.25, roofU: 1.44, wallU: 2.3, floorU: 1.73 };
    if (performance === '1939-1968')
      return { wt: 'single_glazing', ws: 0.25, roofU: 1.38, wallU: 2.15, floorU: 1.61 };
    if (performance === '1969-1975')
      return { wt: 'double_glazing', ws: 0.25, roofU: 1.23, wallU: 1.55, floorU: 1.29 };
    if (performance === '1976-1983')
      return { wt: 'double_glazing', ws: 0.25, roofU: 0.65, wallU: 1.55, floorU: 1.29 };
    if (performance === '1984-1996')
      return { wt: 'double_glazing', ws: 0.25, roofU: 0.45, wallU: 0.71, floorU: 1.29 };
    if (performance === '1997-2008')
      return { wt: 'double_glazing', ws: 0.25, roofU: 0.35, wallU: 0.52, floorU: 0.51 };
    if (performance === '2009-2014')
      return { wt: 'double_glazing', ws: 0.25, roofU: 0.33, wallU: 0.5, floorU: 0.39 };
    if (performance === '2015-2018')
      return { wt: 'double_glazing', ws: 0.25, roofU: 0.32, wallU: 0.43, floorU: 0.32 };
    if (performance === 'E1')
      return { wt: 'double_glazing', ws: 0.25, roofU: 0.32, wallU: 0.43, floorU: 0.32 };
    if (performance === 'E2')
      return { wt: 'double_glazing', ws: 0.25, roofU: 0.32, wallU: 0.43, floorU: 0.32 };
    if (performance === 'E3')
      return { wt: 'double_glazing', ws: 0.25, roofU: 0.32, wallU: 0.43, floorU: 0.32 };
    if (performance === 'E4')
      return { wt: 'double_glazing', ws: 0.25, roofU: 0.32, wallU: 0.43, floorU: 0.32 };
    if (performance === 'UNDEFINED') return { wt: 'double_glazing', ws: 0.25, ...defaultU };
  }
  if (usageCode === 'B30') {
    if (performance === '0-1938')
      return { wt: 'single_glazing', ws: 0.4, roofU: 1.41, wallU: 2.28, floorU: 1.88 };
    if (performance === '1939-1968')
      return { wt: 'single_glazing', ws: 0.4, roofU: 1.41, wallU: 2.28, floorU: 1.88 };
    if (performance === '1969-1975')
      return { wt: 'double_glazing', ws: 0.4, roofU: 1.29, wallU: 2.28, floorU: 1.62 };
    if (performance === '1976-1983')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.57, wallU: 1.98, floorU: 1.17 };
    if (performance === '1984-1996')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.43, wallU: 0.81, floorU: 1.17 };
    if (performance === '1997-2008')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.35, wallU: 0.52, floorU: 0.51 };
    if (performance === '2009-2014')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.31, wallU: 0.5, floorU: 0.39 };
    if (performance === '2015-2018')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.33, wallU: 0.43, floorU: 0.32 };
    if (performance === 'E1')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.33, wallU: 0.43, floorU: 0.32 };
    if (performance === 'E2')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.33, wallU: 0.43, floorU: 0.32 };
    if (performance === 'E3')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.33, wallU: 0.43, floorU: 0.32 };
    if (performance === 'E4')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.33, wallU: 0.43, floorU: 0.32 };
    if (performance === 'UNDEFINED') return { wt: 'double_glazing', ws: 0.4, ...defaultU };
  }
  if (usageCode === 'B31') {
    if (performance === '0-1938')
      return { wt: 'single_glazing', ws: 0.3, roofU: 1.41, wallU: 2.52, floorU: 2.36 };
    if (performance === '1939-1968')
      return { wt: 'single_glazing', ws: 0.3, roofU: 1.41, wallU: 2.52, floorU: 2.36 };
    if (performance === '1969-1975')
      return { wt: 'single_glazing', ws: 0.3, roofU: 1.29, wallU: 2.52, floorU: 2.24 };
    if (performance === '1976-1983')
      return { wt: 'double_glazing', ws: 0.3, roofU: 0.59, wallU: 1.68, floorU: 0.94 };
    if (performance === '1984-1996')
      return { wt: 'double_glazing', ws: 0.3, roofU: 0.52, wallU: 0.71, floorU: 0.94 };
    if (performance === '1997-2008')
      return { wt: 'double_glazing', ws: 0.3, roofU: 0.43, wallU: 0.52, floorU: 0.51 };
    if (performance === '2009-2014')
      return { wt: 'double_glazing', ws: 0.3, roofU: 0.31, wallU: 0.5, floorU: 0.39 };
    if (performance === '2015-2018')
      return { wt: 'double_glazing', ws: 0.3, roofU: 0.33, wallU: 0.43, floorU: 0.32 };
    if (performance === 'E1')
      return { wt: 'double_glazing', ws: 0.3, roofU: 0.33, wallU: 0.43, floorU: 0.32 };
    if (performance === 'E2')
      return { wt: 'double_glazing', ws: 0.3, roofU: 0.33, wallU: 0.43, floorU: 0.32 };
    if (performance === 'E3')
      return { wt: 'double_glazing', ws: 0.3, roofU: 0.33, wallU: 0.43, floorU: 0.32 };
    if (performance === 'E4')
      return { wt: 'double_glazing', ws: 0.3, roofU: 0.33, wallU: 0.43, floorU: 0.32 };
    if (performance === 'UNDEFINED') return { wt: 'double_glazing', ws: 0.3, ...defaultU };
  }
  if (usageCode === 'B33') {
    if (performance === '0-1938')
      return { wt: 'single_glazing', ws: 0.2, roofU: 1.11, wallU: 3.06, floorU: 3.42 };
    if (performance === '1939-1968')
      return { wt: 'single_glazing', ws: 0.2, roofU: 1.06, wallU: 2.77, floorU: 2.85 };
    if (performance === '1969-1975')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.7, wallU: 2.47, floorU: 1.36 };
    if (performance === '1976-1983')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.57, wallU: 2.04, floorU: 0.71 };
    if (performance === '1984-1996')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.46, wallU: 0.85, floorU: 0.71 };
    if (performance === '1997-2008')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.39, wallU: 0.52, floorU: 0.51 };
    if (performance === '2009-2014')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.37, wallU: 0.5, floorU: 0.39 };
    if (performance === '2015-2018')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.37, wallU: 0.42, floorU: 0.27 };
    if (performance === 'E1')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.37, wallU: 0.42, floorU: 0.27 };
    if (performance === 'E2')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.37, wallU: 0.42, floorU: 0.27 };
    if (performance === 'E3')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.37, wallU: 0.42, floorU: 0.27 };
    if (performance === 'E4')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.37, wallU: 0.42, floorU: 0.27 };
    if (performance === 'UNDEFINED') return { wt: 'double_glazing', ws: 0.2, ...defaultU };
  }
  if (usageCode === 'B34') {
    if (performance === '0-1938')
      return { wt: 'single_glazing', ws: 0.2, roofU: 1.15, wallU: 3.16, floorU: 3.56 };
    if (performance === '1939-1968')
      return { wt: 'single_glazing', ws: 0.2, roofU: 0.97, wallU: 2.58, floorU: 2.48 };
    if (performance === '1969-1975')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.61, wallU: 1.98, floorU: 0.89 };
    if (performance === '1976-1983')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.61, wallU: 1.98, floorU: 0.89 };
    if (performance === '1984-1996')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.51, wallU: 0.85, floorU: 0.89 };
    if (performance === '1997-2008')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.39, wallU: 0.52, floorU: 0.51 };
    if (performance === '2009-2014')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.37, wallU: 0.5, floorU: 0.39 };
    if (performance === '2015-2018')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.37, wallU: 0.42, floorU: 0.27 };
    if (performance === 'E1')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.37, wallU: 0.42, floorU: 0.27 };
    if (performance === 'E2')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.37, wallU: 0.42, floorU: 0.27 };
    if (performance === 'E3')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.37, wallU: 0.42, floorU: 0.27 };
    if (performance === 'E4')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.37, wallU: 0.42, floorU: 0.27 };
    if (performance === 'UNDEFINED') return { wt: 'double_glazing', ws: 0.2, ...defaultU };
  }
  if (usageCode === 'B35') {
    if (performance === '0-1938')
      return { wt: 'single_glazing', ws: 0.2, roofU: 1.15, wallU: 3.16, floorU: 3.56 };
    if (performance === '1939-1968')
      return { wt: 'single_glazing', ws: 0.2, roofU: 1.04, wallU: 2.58, floorU: 2.48 };
    if (performance === '1969-1975')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.58, wallU: 1.98, floorU: 0.89 };
    if (performance === '1976-1983')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.58, wallU: 1.98, floorU: 0.89 };
    if (performance === '1984-1996')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.47, wallU: 0.85, floorU: 0.89 };
    if (performance === '1997-2008')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.39, wallU: 0.52, floorU: 0.51 };
    if (performance === '2009-2014')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.37, wallU: 0.5, floorU: 0.39 };
    if (performance === '2015-2018')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.37, wallU: 0.42, floorU: 0.27 };
    if (performance === 'E1')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.37, wallU: 0.42, floorU: 0.27 };
    if (performance === 'E2')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.37, wallU: 0.42, floorU: 0.27 };
    if (performance === 'E3')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.37, wallU: 0.42, floorU: 0.27 };
    if (performance === 'E4')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.37, wallU: 0.42, floorU: 0.27 };
    if (performance === 'UNDEFINED') return { wt: 'double_glazing', ws: 0.2, ...defaultU };
  }
  if (usageCode === 'B36') {
    if (performance === '0-1938')
      return { wt: 'single_glazing', ws: 0.2, roofU: 1.15, wallU: 3.16, floorU: 3.56 };
    if (performance === '1939-1968')
      return { wt: 'single_glazing', ws: 0.2, roofU: 1.04, wallU: 2.58, floorU: 2.48 };
    if (performance === '1969-1975')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.74, wallU: 1.98, floorU: 0.89 };
    if (performance === '1976-1983')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.58, wallU: 1.98, floorU: 0.89 };
    if (performance === '1984-1996')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.47, wallU: 0.85, floorU: 0.89 };
    if (performance === '1997-2008')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.39, wallU: 0.52, floorU: 0.51 };
    if (performance === '2009-2014')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.37, wallU: 0.5, floorU: 0.39 };
    if (performance === '2015-2018')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.37, wallU: 0.42, floorU: 0.27 };
    if (performance === 'E1')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.37, wallU: 0.42, floorU: 0.27 };
    if (performance === 'E2')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.37, wallU: 0.42, floorU: 0.27 };
    if (performance === 'E3')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.37, wallU: 0.42, floorU: 0.27 };
    if (performance === 'E4')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.37, wallU: 0.42, floorU: 0.27 };
    if (performance === 'UNDEFINED') return { wt: 'double_glazing', ws: 0.2, ...defaultU };
  }
  if (usageCode === 'B37') {
    if (performance === '0-1938')
      return { wt: 'single_glazing', ws: 0.4, roofU: 0.92, wallU: 2.3, floorU: 1.73 };
    if (performance === '1939-1968')
      return { wt: 'single_glazing', ws: 0.4, roofU: 0.86, wallU: 2.15, floorU: 1.61 };
    if (performance === '1969-1975')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.65, wallU: 1.55, floorU: 1.29 };
    if (performance === '1976-1983')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.65, wallU: 1.55, floorU: 1.29 };
    if (performance === '1984-1996')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.54, wallU: 0.71, floorU: 1.29 };
    if (performance === '1997-2008')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.39, wallU: 0.52, floorU: 0.51 };
    if (performance === '2009-2014')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.37, wallU: 0.5, floorU: 0.39 };
    if (performance === '2015-2018')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.32, wallU: 0.43, floorU: 0.32 };
    if (performance === 'E1')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.32, wallU: 0.43, floorU: 0.32 };
    if (performance === 'E2')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.32, wallU: 0.43, floorU: 0.32 };
    if (performance === 'E3')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.32, wallU: 0.43, floorU: 0.32 };
    if (performance === 'E4')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.32, wallU: 0.43, floorU: 0.32 };
    if (performance === 'UNDEFINED') return { wt: 'double_glazing', ws: 0.4, ...defaultU };
  }
  if (usageCode === 'B39') {
    if (performance === '0-1938')
      return { wt: 'single_glazing', ws: 0.35, roofU: 1.0, wallU: 2.75, floorU: 3.0 };
    if (performance === '1939-1968')
      return { wt: 'single_glazing', ws: 0.35, roofU: 1.0, wallU: 2.75, floorU: 3.0 };
    if (performance === '1969-1975')
      return { wt: 'single_glazing', ws: 0.35, roofU: 1.0, wallU: 2.75, floorU: 3.1 };
    if (performance === '1976-1983')
      return { wt: 'double_glazing', ws: 0.35, roofU: 0.5, wallU: 1.05, floorU: 0.5 };
    if (performance === '1984-1996')
      return { wt: 'double_glazing', ws: 0.35, roofU: 0.4, wallU: 0.5, floorU: 0.5 };
    if (performance === '1997-2008')
      return { wt: 'double_glazing', ws: 0.35, roofU: 0.36, wallU: 0.47, floorU: 0.47 };
    if (performance === '2009-2014')
      return { wt: 'double_glazing', ws: 0.35, roofU: 0.34, wallU: 0.45, floorU: 0.36 };
    if (performance === '2015-2018')
      return { wt: 'double_glazing', ws: 0.35, roofU: 0.3, wallU: 0.4, floorU: 0.3 };
    if (performance === 'E1')
      return { wt: 'double_glazing', ws: 0.35, roofU: 0.3, wallU: 0.4, floorU: 0.3 };
    if (performance === 'E2')
      return { wt: 'double_glazing', ws: 0.35, roofU: 0.3, wallU: 0.4, floorU: 0.3 };
    if (performance === 'E3')
      return { wt: 'double_glazing', ws: 0.35, roofU: 0.3, wallU: 0.4, floorU: 0.3 };
    if (performance === 'E4')
      return { wt: 'double_glazing', ws: 0.35, roofU: 0.3, wallU: 0.4, floorU: 0.3 };
    if (performance === 'UNDEFINED') return { wt: 'double_glazing', ws: 0.35, ...defaultU };
  }
  if (usageCode === 'B40') {
    if (performance === '0-1938')
      return { wt: 'single_glazing', ws: 0.25, roofU: 0.77, wallU: 1.9, floorU: 1.43 };
    if (performance === '1939-1968')
      return { wt: 'single_glazing', ws: 0.25, roofU: 0.77, wallU: 1.9, floorU: 1.43 };
    if (performance === '1969-1975')
      return { wt: 'single_glazing', ws: 0.25, roofU: 0.64, wallU: 1.9, floorU: 1.43 };
    if (performance === '1976-1983')
      return { wt: 'single_glazing', ws: 0.25, roofU: 0.64, wallU: 1.05, floorU: 0.85 };
    if (performance === '1984-1996')
      return { wt: 'double_glazing', ws: 0.25, roofU: 0.45, wallU: 0.75, floorU: 0.57 };
    if (performance === '1997-2008')
      return { wt: 'double_glazing', ws: 0.25, roofU: 0.35, wallU: 0.62, floorU: 0.51 };
    if (performance === '2009-2014')
      return { wt: 'double_glazing', ws: 0.25, roofU: 0.34, wallU: 0.4, floorU: 0.38 };
    if (performance === '2015-2018')
      return { wt: 'double_glazing', ws: 0.25, roofU: 0.3, wallU: 0.4, floorU: 0.3 };
    if (performance === 'E1')
      return { wt: 'double_glazing', ws: 0.25, roofU: 0.3, wallU: 0.4, floorU: 0.3 };
    if (performance === 'E2')
      return { wt: 'double_glazing', ws: 0.25, roofU: 0.3, wallU: 0.4, floorU: 0.3 };
    if (performance === 'E3')
      return { wt: 'double_glazing', ws: 0.25, roofU: 0.3, wallU: 0.4, floorU: 0.3 };
    if (performance === 'E4')
      return { wt: 'double_glazing', ws: 0.25, roofU: 0.3, wallU: 0.4, floorU: 0.3 };
    if (performance === 'UNDEFINED') return { wt: 'double_glazing', ws: 0.25, ...defaultU };
  }
  if (usageCode === 'B43') {
    if (performance === '0-1938')
      return { wt: 'single_glazing', ws: 0.4, roofU: 1.15, wallU: 3.16, floorU: 3.55 };
    if (performance === '1939-1968')
      return { wt: 'single_glazing', ws: 0.4, roofU: 0.97, wallU: 2.7, floorU: 2.78 };
    if (performance === '1969-1975')
      return { wt: 'single_glazing', ws: 0.4, roofU: 0.88, wallU: 2.3, floorU: 2.63 };
    if (performance === '1976-1983')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.59, wallU: 2.02, floorU: 0.77 };
    if (performance === '1984-1996')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.48, wallU: 0.85, floorU: 0.77 };
    if (performance === '1997-2008')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.39, wallU: 0.52, floorU: 0.51 };
    if (performance === '2009-2014')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.37, wallU: 0.5, floorU: 0.39 };
    if (performance === '2015-2018')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.32, wallU: 0.46, floorU: 0.32 };
    if (performance === 'E1')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.32, wallU: 0.46, floorU: 0.32 };
    if (performance === 'E2')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.32, wallU: 0.46, floorU: 0.32 };
    if (performance === 'E3')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.32, wallU: 0.46, floorU: 0.32 };
    if (performance === 'E4')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.32, wallU: 0.46, floorU: 0.32 };
    if (performance === 'UNDEFINED') return { wt: 'double_glazing', ws: 0.4, ...defaultU };
  }
  if (usageCode === 'B44') {
    if (performance === '0-1938')
      return { wt: 'single_glazing', ws: 0.3, roofU: 0.84, wallU: 2.96, floorU: 1.99 };
    if (performance === '1939-1968')
      return { wt: 'single_glazing', ws: 0.3, roofU: 0.84, wallU: 1.83, floorU: 1.99 };
    if (performance === '1969-1975')
      return { wt: 'single_glazing', ws: 0.3, roofU: 0.76, wallU: 1.83, floorU: 1.9 };
    if (performance === '1976-1983')
      return { wt: 'double_glazing', ws: 0.3, roofU: 0.48, wallU: 0.87, floorU: 0.54 };
    if (performance === '1984-1996')
      return { wt: 'double_glazing', ws: 0.3, roofU: 0.36, wallU: 0.56, floorU: 0.54 };
    if (performance === '1997-2008')
      return { wt: 'double_glazing', ws: 0.3, roofU: 0.34, wallU: 0.51, floorU: 0.52 };
    if (performance === '2009-2014')
      return { wt: 'double_glazing', ws: 0.3, roofU: 0.33, wallU: 0.48, floorU: 0.47 };
    if (performance === '2015-2018')
      return { wt: 'double_glazing', ws: 0.3, roofU: 0.28, wallU: 0.44, floorU: 0.31 };
    if (performance === 'E1')
      return { wt: 'double_glazing', ws: 0.3, roofU: 0.28, wallU: 0.44, floorU: 0.31 };
    if (performance === 'E2')
      return { wt: 'double_glazing', ws: 0.3, roofU: 0.28, wallU: 0.44, floorU: 0.31 };
    if (performance === 'E3')
      return { wt: 'double_glazing', ws: 0.3, roofU: 0.28, wallU: 0.44, floorU: 0.31 };
    if (performance === 'E4')
      return { wt: 'double_glazing', ws: 0.3, roofU: 0.28, wallU: 0.44, floorU: 0.31 };
    if (performance === 'UNDEFINED') return { wt: 'double_glazing', ws: 0.3, ...defaultU };
  }
  if (usageCode === 'B46') {
    if (performance === '0-1938')
      return { wt: 'single_glazing', ws: 0.2, roofU: 1.07, wallU: 2.96, floorU: 3.23 };
    if (performance === '1939-1968')
      return { wt: 'single_glazing', ws: 0.2, roofU: 1.07, wallU: 2.96, floorU: 3.23 };
    if (performance === '1969-1975')
      return { wt: 'single_glazing', ws: 0.2, roofU: 0.62, wallU: 2.52, floorU: 1.84 };
    if (performance === '1976-1983')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.54, wallU: 1.68, floorU: 0.54 };
    if (performance === '1984-1996')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.43, wallU: 0.85, floorU: 0.54 };
    if (performance === '1997-2008')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.39, wallU: 0.52, floorU: 0.51 };
    if (performance === '2009-2014')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.37, wallU: 0.48, floorU: 0.39 };
    if (performance === '2015-2018')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.32, wallU: 0.43, floorU: 0.32 };
    if (performance === 'E1')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.32, wallU: 0.43, floorU: 0.32 };
    if (performance === 'E2')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.32, wallU: 0.43, floorU: 0.32 };
    if (performance === 'E3')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.32, wallU: 0.43, floorU: 0.32 };
    if (performance === 'E4')
      return { wt: 'double_glazing', ws: 0.2, roofU: 0.32, wallU: 0.43, floorU: 0.32 };
    if (performance === 'UNDEFINED') return { wt: 'double_glazing', ws: 0.2, ...defaultU };
  }
  if (usageCode === 'B47') {
    if (performance === '0-1938')
      return { wt: 'single_glazing', ws: 0.4, roofU: 0.75, wallU: 1.99, floorU: 2.09 };
    if (performance === '1939-1968')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.71, wallU: 1.62, floorU: 1.34 };
    if (performance === '1969-1975')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.68, wallU: 1.02, floorU: 1.19 };
    if (performance === '1976-1983')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.51, wallU: 1.02, floorU: 1.19 };
    if (performance === '1984-1996')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.46, wallU: 0.87, floorU: 1.19 };
    if (performance === '1997-2008')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.43, wallU: 0.85, floorU: 0.82 };
    if (performance === '2009-2014')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.42, wallU: 0.84, floorU: 0.77 };
    if (performance === '2015-2018')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.4, wallU: 0.82, floorU: 0.74 };
    if (performance === 'E1')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.4, wallU: 0.82, floorU: 0.74 };
    if (performance === 'E2')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.4, wallU: 0.82, floorU: 0.74 };
    if (performance === 'E3')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.4, wallU: 0.82, floorU: 0.74 };
    if (performance === 'E4')
      return { wt: 'double_glazing', ws: 0.4, roofU: 0.4, wallU: 0.82, floorU: 0.74 };
    if (performance === 'UNDEFINED') return { wt: 'double_glazing', ws: 0.4, ...defaultU };
  }

  return { wt: 'double_glazing', ws: 0.1, ...defaultU };
}

export function lookupDefaultTabulatedValues(
  performance: Performance,
  usageCode: UsageCode,
): {
  windowType: StandardWindowType;
  windowShare: number;
  roofU: number;
  wallU: number;
  floorU: number;
} {
  const parts = lookupParts(performance, usageCode);
  return {
    windowType: parts.wt,
    windowShare: parts.ws,
    roofU: parts.roofU,
    wallU: parts.wallU,
    floorU: parts.floorU,
  };
}

import { LoadCurveModeEnum, LoadCurveUnitEnum } from '../api-client';
import type { LoadCurve, LoadCurveApi, LoadCurves } from './load-curve.interface';

export class LoadCurveApiMock implements LoadCurveApi {
  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
  async getLoadCurves(withDefault: boolean, projectId?: number): Promise<LoadCurves> {
    const defaultLoadCurves: LoadCurve[] = [];
    const projectLoadCurves: LoadCurve[] = [];
    const userLoadCurves: LoadCurve[] = [
      {
        _key: 10969,
        source: 'powerdis@efficacity.com',
        name: 'Climatisation',
        description: '',
        default: false,
        mode: LoadCurveModeEnum.Cooling,
        unit: LoadCurveUnitEnum.W,
        extension: 'xlsx',
        creation_time: '2024-09-30 07:46:14.692964',
      },
      {
        _key: 10959,
        source: 'powerdis@efficacity.com',
        name: 'chauffage',
        description: '',
        default: false,
        mode: LoadCurveModeEnum.Heating,
        unit: LoadCurveUnitEnum.W,
        extension: 'xlsx',
        creation_time: '2024-09-30 07:45:59.519907',
      },
      {
        _key: 11106,
        source: 'powerdis@efficacity.com',
        name: 'ECS',
        description: '',
        default: false,
        mode: LoadCurveModeEnum.Dhw,
        unit: LoadCurveUnitEnum.W,
        extension: 'xlsx',
        creation_time: '2024-09-30 09:59:36.133599',
      },
      {
        _key: 11116,
        source: 'powerdis@efficacity.com',
        name: 'Electricite',
        description: '',
        default: false,
        mode: LoadCurveModeEnum.Electricity,
        unit: LoadCurveUnitEnum.W,
        extension: 'xlsx',
        creation_time: '2024-09-30 09:59:50.480860',
      },
    ];

    return { default: defaultLoadCurves, user: userLoadCurves, project: projectLoadCurves };
  }
}

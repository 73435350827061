/* eslint-disable class-methods-use-this */

import type { ClosedRing } from '@/components/soon-shared/util';
import { geoJSONCoordinatesToPolygonGeometry } from '@/util/geography';
import { polygon, tesselate, transformScale } from '@turf/turf';
import { ForbiddenAccessError, InvalidInputError, NotFoundError } from '../errors';
import { checkProjectsAccess } from '../project/project.mock';
import { performanceOptions, usageCodeOptions } from '../thermal-zone/thermal-zone.interface';
import { type CurrentUseState, type ResultType } from '../types';
import type {
  ImportedBuilding,
  Study,
  StudyApi,
  StudyForm,
  StudyInfo,
  StudySimulationsType,
} from './study.interface';

const project1Studies: Study[] = [
  {
    id: 1,
    projectId: 1,
    name: 'ETAT INITIAL EURALYS',
    description: '10000 batiments',
    building_image_coordinates: [],
    network_image_coordinates: [],
    savedAt: new Date(),
    state: {
      statesByStep: {
        buildingModelling: {
          state: 'LOCKED',
          url: '/study/1/buildings-modelling',
        },
        resultsBuildingModelling: {
          state: 'SUCCESS',
          url: '/study/1/results-demands',
        },
        systemsModelling: {
          state: 'LOCKED',
          url: '/study/1/study-systems',
        },
        sizing: {
          state: 'LOCKED',
          url: '/study/1/study-systems',
        },
        resultsConsumptions: {
          state: 'RUNNING',
          url: '/study/1/results-consumptions',
        },
      },
    },
  },
  {
    id: 2,
    projectId: 1,
    name: 'ETAT INITIAL EURALYS CALIBRAGE',
    description: '',
    building_image_coordinates: [],
    network_image_coordinates: [],
    savedAt: new Date(),
    state: {
      statesByStep: {
        buildingModelling: {
          state: 'ACTION_REQUIRED',
          url: '/study/2/buildings-modelling',
        },
        resultsBuildingModelling: {
          state: 'FUTURE',
          url: '/study/2/results-demands',
        },
        systemsModelling: {
          state: 'FUTURE',
          url: '/study/2/study-systems',
        },
        sizing: {
          state: 'FUTURE',
          url: '/study/2/study-systems',
        },
        resultsConsumptions: {
          state: 'FUTURE',
          url: '/study/2/results-consumptions',
        },
      },
    },
  },
  {
    id: 3,
    projectId: 1,
    name: 'ETAT INITIAL EURALYS RACCORDEMENT RESEAU',
    description: '',
    building_image_coordinates: [],
    network_image_coordinates: [],
    savedAt: new Date(),
    state: {
      statesByStep: {
        buildingModelling: {
          state: 'LOCKED',
          url: '/study/3/buildings-modelling',
        },
        resultsBuildingModelling: {
          state: 'RUNNING',
          url: '/study/3/results-demands',
        },
        systemsModelling: {
          state: 'FUTURE',
          url: '/study/3/study-systems',
        },
        resultsConsumptions: {
          state: 'FUTURE',
          url: '/study/3/results-consumptions',
        },
      },
    },
  },
  {
    id: 4,
    projectId: 1,
    name: 'ETAT INITIAL EURALYS CORRIGE APRES POINTAGE',
    description: '',
    building_image_coordinates: [],
    network_image_coordinates: [],
    savedAt: new Date(),
    state: {
      statesByStep: {
        buildingModelling: {
          state: 'SUCCESS',
          url: '/study/4/buildings-modelling',
        },
        resultsBuildingModelling: {
          state: 'SUCCESS',
          url: '/study/4/results-demands',
        },
        systemsModelling: {
          state: 'ACTION_REQUIRED',
          url: '/study/4/study-systems',
        },
        resultsConsumptions: {
          state: 'FUTURE',
          url: '/study/4/results-consumptions',
        },
      },
    },
  },
  {
    id: 5,
    projectId: 1,
    name: 'ETAT INITIAL EURALYS CORRIGE APRES POINTAGE SANS SUPRESSION',
    description: '',
    building_image_coordinates: [],
    network_image_coordinates: [],
    savedAt: new Date(),
    state: {
      statesByStep: {
        buildingModelling: {
          state: 'LOCKED',
          url: '/study/5/buildings-modelling',
        },
        resultsBuildingModelling: {
          state: 'SUCCESS',
          url: '/study/5/results-demands',
        },
        systemsModelling: {
          state: 'LOCKED',
          url: '/study/5/study-systems',
        },
        sizing: {
          state: 'RUNNING',
          url: '/study/5/study-systems',
        },
        resultsConsumptions: {
          state: 'FUTURE',
          url: '/study/5/results-consumptions',
        },
      },
    },
  },
  {
    id: 6,
    projectId: 1,
    name: 'DUPLICAT ETAT INITIAL EURALYS CORRIGE APRES POINTAGE SANS SUPRESSION',
    description: '',
    building_image_coordinates: [],
    network_image_coordinates: [],
    savedAt: new Date(),
    state: {
      statesByStep: {
        buildingModelling: {
          state: 'SUCCESS',
          url: '/study/6/buildings-modelling',
        },
        resultsBuildingModelling: {
          state: 'SUCCESS',
          url: '/study/6/results-demands',
        },
        systemsModelling: {
          state: 'ACTION_REQUIRED',
          url: '/study/6/study-systems',
        },
        sizing: {
          state: 'SUCCESS',
          url: '/study/6/study-systems',
        },
        resultsConsumptions: {
          state: 'FUTURE',
          url: '/study/6/results-consumptions',
        },
      },
    },
  },
  {
    id: 14,
    projectId: 1,
    name: 'ETAT INITIAL EURALYS CALIBRAGE avec image importée',
    description: '',
    building_image_coordinates: [
      { lat: 48.840436933131805, lng: 2.58379340171814 },
      { lat: 48.84082530159813, lng: 2.5846838951110844 },
      { lat: 48.83985084410871, lng: 2.5843834877014165 },
      { lat: 48.84023921711944, lng: 2.585273981094361 },
    ],
    network_image_coordinates: [],
    savedAt: new Date(),
    state: {
      statesByStep: {
        buildingModelling: {
          state: 'SUCCESS',
          url: '/study/14/buildings-modelling',
        },
        resultsBuildingModelling: {
          state: 'FUTURE',
          url: '/study/14/results-demands',
        },
        systemsModelling: {
          state: 'FUTURE',
          url: '/study/14/study-systems',
        },
        sizing: {
          state: 'FUTURE',
          url: '/study/14/study-systems',
        },
        resultsConsumptions: {
          state: 'FUTURE',
          url: '/study/14/results-consumptions',
        },
      },
    },
  },
];

const project2Studies: Study[] = [
  {
    id: 7,
    projectId: 2,
    name: 'TEST VARIANTE UTILISEE PAR UN UTILISATEUR',
    description: 'version de bruz initialisé',
    building_image_coordinates: [],
    network_image_coordinates: [],
    savedAt: new Date(),
    state: {
      statesByStep: {
        buildingModelling: {
          state: 'SUCCESS',
          url: '/study/7/buildings-modelling',
        },
        resultsBuildingModelling: {
          state: 'SUCCESS',
          url: '/study/7/results-demands',
        },
        systemsModelling: {
          state: 'ACTION_REQUIRED',
          url: '/study/7/study-systems',
        },
        resultsConsumptions: {
          state: 'FUTURE',
          url: '/study/7/results-consumptions',
        },
      },
    },
  },
  {
    id: 8,
    projectId: 2,
    name: 'Essai2 sans dimensionnement',
    description:
      'Scénario 3 : Changement de technologie de panneaux pour la Halle > Monocristallin',
    building_image_coordinates: [],
    network_image_coordinates: [],
    savedAt: new Date(),
    state: {
      statesByStep: {
        buildingModelling: {
          state: 'SUCCESS',
          url: '/study/8/buildings-modelling',
        },
        resultsBuildingModelling: {
          state: 'SUCCESS',
          url: '/study/8/results-demands',
        },
        systemsModelling: {
          state: 'ACTION_REQUIRED',
          url: '/study/8/study-systems',
        },
        resultsConsumptions: {
          state: 'ERROR',
          url: '/study/8/results-consumptions',
        },
      },
    },
  },
  {
    id: 9,
    projectId: 2,
    name: 'Essai4 sans dimensionnement',
    description:
      'Scénario 3 : Changement de technologie de panneaux pour la Halle > Monocristallin',
    building_image_coordinates: [],
    network_image_coordinates: [],
    savedAt: new Date(),
    state: {
      statesByStep: {
        buildingModelling: {
          state: 'SUCCESS',
          url: '/study/9/buildings-modelling',
        },
        resultsBuildingModelling: {
          state: 'SUCCESS',
          url: '/study/9/results-demands',
        },
        systemsModelling: {
          state: 'ACTION_REQUIRED',
          url: '/study/9/study-systems',
        },
        resultsConsumptions: {
          state: 'ERROR',
          url: '/study/9/results-consumptions',
        },
      },
    },
  },
  {
    id: 10,
    projectId: 2,
    name: 'Essai5 sans dimensionnement',
    description:
      'Scénario 3 : Changement de technologie de panneaux pour la Halle > Monocristallin',
    building_image_coordinates: [],
    network_image_coordinates: [],
    savedAt: new Date(),
    state: {
      statesByStep: {
        buildingModelling: {
          state: 'LOCKED',
          url: '/study/10/buildings-modelling',
        },
        resultsBuildingModelling: {
          state: 'RUNNING',
          url: '/study/10/results-demands',
        },
        systemsModelling: {
          state: 'FUTURE',
          url: '/study/10/study-systems',
        },
        resultsConsumptions: {
          state: 'FUTURE',
          url: '/study/10/results-consumptions',
        },
      },
    },
  },
  {
    id: 11,
    projectId: 2,
    name: 'Essai6 sans dimensionnement',
    description:
      'Scénario 3 : Changement de technologie de panneaux pour la Halle > Monocristallin',
    building_image_coordinates: [],
    network_image_coordinates: [],
    savedAt: new Date(),
    state: {
      statesByStep: {
        buildingModelling: {
          state: 'SUCCESS',
          url: '/study/11/buildings-modelling',
        },
        resultsBuildingModelling: {
          state: 'SUCCESS',
          url: '/study/11/results-demands',
        },
        systemsModelling: {
          state: 'ACTION_REQUIRED',
          url: '/study/11/study-systems',
        },
        resultsConsumptions: {
          state: 'CANCELED',
          url: '/study/11/results-consumptions',
        },
      },
    },
  },
];

const project4Studies: Study[] = [
  {
    id: 13,
    projectId: 4,
    name: 'Une seule variante',
    description: '',
    building_image_coordinates: [],
    network_image_coordinates: [],
    savedAt: new Date(),
    state: {
      statesByStep: {
        buildingModelling: {
          state: 'SUCCESS',
          url: '/study/9/buildings-modelling',
        },
        resultsBuildingModelling: {
          state: 'SUCCESS',
          url: '/study/9/results-demands',
        },
        systemsModelling: {
          state: 'SUCCESS',
          url: '/study/9/study-systems',
        },
        resultsConsumptions: {
          state: 'SUCCESS',
          url: '/study/9/results-consumptions',
        },
      },
    },
  },
];
/*
  Please refer to the project mock identify their rights
*/

const user1studiesIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13];
const otherStudiesIds = [12];

const availableStudy = {
  isCurrentlyUsed: false,
};

const notAvailableStudy = {
  isCurrentlyUsed: true,
  usedBy: 'foo.bar@efficacity.com',
};

const checkStudiesAccess = (studyId: number) => {
  if (![...user1studiesIds, ...otherStudiesIds].includes(studyId)) throw new NotFoundError();
  if (!user1studiesIds.includes(studyId)) throw new ForbiddenAccessError();
};
export class StudyApiMock implements StudyApi {
  async createStudy(projectId: number, studyData: StudyForm): Promise<number> {
    checkProjectsAccess(projectId);
    if (studyData.name!.length === 0) throw InvalidInputError;
    return 10;
  }

  async getProjectStudies(projectId: number): Promise<Study[]> {
    checkProjectsAccess(projectId);
    if (projectId === 1) return project1Studies;
    else if (projectId === 2) return project2Studies;
    else if (projectId === 4) return project4Studies;
    else return [];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async updateStudy(studyId: number, studyData: StudyForm): Promise<number> {
    checkStudiesAccess(studyId);
    return 1;
  }

  async duplicateStudy(
    studyId: number,
    newStudyData: StudyForm,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resultsToDuplicate: ResultType,
  ): Promise<Study> {
    checkStudiesAccess(studyId);
    if (newStudyData.name!.length === 0) throw new InvalidInputError();
    return project1Studies[0];
  }

  async deleteStudy(studyId: number): Promise<void> {
    checkStudiesAccess(studyId);
  }

  async getCurrentUseState(studyId: number): Promise<CurrentUseState> {
    if (studyId === 7) return notAvailableStudy;
    return availableStudy;
  }

  getStudyById(studyId: number): Study {
    const foundStudy = [...project1Studies, ...project2Studies, ...project4Studies].find(
      (study) => study.id === studyId,
    );

    if (!foundStudy) throw new Error('study not found within projects');
    return foundStudy;
  }

  async getStudy(studyId: number): Promise<Study> {
    return this.getStudyById(studyId);
  }

  async getStudyInfo(studyId: number): Promise<StudyInfo> {
    const study = this.getStudyById(studyId);

    return {
      id: studyId,
      projectId: study.projectId,
      name: study.name,
      description: study.description,
      building_image_coordinates: study.building_image_coordinates,
      network_image_coordinates: study.network_image_coordinates,
      savedAt: new Date(),
    };
  }

  async importBuildings(importPerimeter: ClosedRing): Promise<ImportedBuilding[]> {
    await new Promise((resolve) => {
      setTimeout(resolve, 3000);
    });
    const rawPolygons = tesselate(polygon([importPerimeter.map(({ lat, lng }) => [lng, lat])]));
    const importedBuildings: ImportedBuilding[] = rawPolygons.features.map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (feature: any) => ({
        externalId: `BATIMENT0000${Math.floor(Math.random() * (20000 - 10000 + 1)) + 10000}`,
        geometry: geoJSONCoordinatesToPolygonGeometry(
          transformScale(feature.geometry, 0.9).coordinates,
        ),
        properties: {
          altitude: 94,
          floor_count: 2,
          mean_floor_height: 2.7,
          performance: performanceOptions[Math.floor(Math.random() * performanceOptions.length)],
          usage_zone_code: usageCodeOptions[Math.floor(Math.random() * usageCodeOptions.length)],
          year: 2015,
        },
      }),
    );
    return importedBuildings;
  }

  async startSimulation(
    ignoredStudyId: number,
    ignoredSimulationType: StudySimulationsType,
    // eslint-disable-next-line no-empty-function
  ): Promise<void> {}

  async deleteSimulationResults(
    ignoredStudyId: number,
    ignoredSimulationType: StudySimulationsType,
    // eslint-disable-next-line no-empty-function
  ): Promise<void> {}
}

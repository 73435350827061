import type { StandardWindowType, UsageCode } from '@/api/thermal-zone/thermal-zone.interface';
import _ from 'lodash';
import { computeHeight } from './thermal-zone-computed-properties';

type InfiltrationRateDataItem = {
  usage_zone_code: UsageCode[];
  a: number;
  infiltration_p4: { [key in StandardWindowType]: number };
};

// Source:
// https://efficacityfr.sharepoint.com/:w:/s/Powerdis/EYdEcrueC6xFkqx2DD9H8-cBYbMje2XfpH8BgS_oy6KCbQ
const infiltrationRateData: InfiltrationRateDataItem[] = [
  {
    usage_zone_code: [
      'B00',
      'B01',
      'B02',
      'B03',
      'B07',
      'B08',
      'B12',
      'B16',
      'B18',
      'B19',
      'B20',
      'B21',
      'B27',
      'B28',
      'B29',
      'B30',
      'B31',
      'B33',
      'B34',
      'B35',
      'B36',
      'B37',
      'B39',
      'B40',
      'B46',
      'B47',
    ],
    a: 1.1,
    infiltration_p4: {
      triple_glazing: 1.2,
      double_glazing: 2.4,
      single_glazing: 3.3,
    },
  },
  {
    usage_zone_code: [
      'B04',
      'B05',
      'B06',
      'B09',
      'B10',
      'B11',
      'B13',
      'B14',
      'B15',
      'B22',
      'B23',
      'B25',
      'B43',
      'B44',
    ],
    a: 1.8,
    infiltration_p4: {
      triple_glazing: 0.6,
      double_glazing: 1.2,
      single_glazing: 2,
    },
  },
  {
    usage_zone_code: ['B26'],
    a: 1.8,
    infiltration_p4: {
      triple_glazing: 0.7,
      double_glazing: 1.8,
      single_glazing: 2.3,
    },
  },
];

const exposition: number = 0.03;

export function getInfiltrationRate(
  usageCode: UsageCode,
  windowType: StandardWindowType,
  meanFloorHeight: number,
  floorCount: number,
): number {
  const infiltrationData: InfiltrationRateDataItem | undefined = infiltrationRateData.find(
    (data: InfiltrationRateDataItem) => data.usage_zone_code.includes(usageCode),
  );

  if (!infiltrationData) {
    throw new Error('No infiltration data found for the given usage code.');
  }
  const n50 =
    (infiltrationData.a * infiltrationData.infiltration_p4[windowType] * (floorCount + 1)) /
    floorCount;

  const height = computeHeight(floorCount, meanFloorHeight);
  const epsilon = ((): number => {
    if (height <= 10) {
      return 1;
    } else if (height > 10 && height <= 30) {
      return 1.2;
    } else {
      return 1.5;
    }
  })();
  const defaultInfiltrationRate: number = 2 * n50 * exposition * epsilon;

  return _.round(defaultInfiltrationRate, 2);
}

import {
  ScenarioCategoryEnum as scenarioCategoryOptions,
  ScenarioUnitEnum as scenarioUnitOptions,
  type ScenarioCategoryEnum,
  type ScenarioUnitEnum,
} from '../api-client';
import { usageCodeOptions, type UsageCode } from '../thermal-zone/thermal-zone.interface';
import type { AllScenarios, ScenarioApi, ScenariosByScope } from './scenario.interface';

/*
 * Rule for scenario IDs:
 * {service index}{scope index}{scenario index on 2 digits}
 *
 * where:
 * - service index is from 1 (heating) to 6 (specific electricity) in the same order as
 *   `ThermalZoneProperties`;
 * - scope index is 0 for default, 1 for user, 2 for project;
 * - scenario index within its scope (for default, matches the usage code).
 */
export const generateMockScenarioId = (
  serviceIndex: number,
  scopeIndex: number,
  scenarioIndex: number,
): number => 1000 * serviceIndex + 100 * scopeIndex + scenarioIndex;

export const getUsageCodeIndex = (usageCode: string): number =>
  parseInt(usageCode.substring(1), 10);

function buildScenarios(
  categoryKey: ScenarioCategoryEnum,
  categoryIndex: number,
  categoryLabel: string,
  displayProperty: 'absolute' | 'relative',
  unit: ScenarioUnitEnum,
): ScenariosByScope {
  const commonProperties = {
    category: categoryKey,
    display_property: displayProperty,
    source: 'Mock Source',
    unit,
    value_max: 1,
  };

  return {
    default: [
      // Pick some scenarios in each category.
      // Reflect the edge cases found in the database: some usage codes, including the 'unknown'
      // one (B00), have no default scenario. Also, some scenarios map to unknown typologies.
      // OtherPrimarySecondaryTertiaryActivities category
      'B05',
      'B06',
      // PublicFacilitiesServices category
      'B14',
      'B15',
      // CommercialServiceActivities category
      'B23',
      'B24', // unknown typology (used to be a hotel category)
      // ResidentialBuildings category
      'B25',
      'B26',
    ].map((usageCode) => {
      const isKnownUsageCode = (usageCodeOptions as readonly string[]).includes(usageCode);
      return {
        ...commonProperties,
        _key: generateMockScenarioId(
          categoryIndex,
          0,
          getUsageCodeIndex(usageCode as UsageCode),
        ),
        code_typology: isKnownUsageCode ? (usageCode as UsageCode) : undefined,
        default: true,
        scenario_name: `${categoryLabel} - Default scenario for ${usageCode}`,
      };
    }),
    user: [1, 2].map((index) => ({
      ...commonProperties,
      _key: generateMockScenarioId(categoryIndex, 1, index),
      code_typology: 'B00',
      default: false,
      scenario_name: `${categoryLabel} - User scenario #${index}`,
    })),
    project: [1, 2].map((index) => ({
      ...commonProperties,
      _key: generateMockScenarioId(categoryIndex, 2, index),
      code_typology: 'B00',
      default: false,
      scenario_name: `${categoryLabel} - Project scenario #${index}`,
    })),
  };
}

export class ScenarioApiMock implements ScenarioApi {
  // eslint-disable-next-line class-methods-use-this
  async getAllScenarios(): Promise<AllScenarios> {
    return {
      heating: buildScenarios(
        scenarioCategoryOptions.HeatingSetPoint,
        1,
        'Heating',
        'absolute',
        scenarioUnitOptions.C,
      ),
      cooling: buildScenarios(
        scenarioCategoryOptions.CoolingSetPoint,
        2,
        'Cooling',
        'absolute',
        scenarioUnitOptions.C,
      ),
      airChangeRate: buildScenarios(
        scenarioCategoryOptions.AirChangeRateSetPoint,
        3,
        'Air Change Rate',
        'relative',
        scenarioUnitOptions.VolM3,
      ),
      internalGainMetabolism: buildScenarios(
        scenarioCategoryOptions.InternalGainMetabolism,
        4,
        'Internal Gain Metabolism',
        'relative',
        scenarioUnitOptions.WM2,
      ),
      domesticHotWater: buildScenarios(
        scenarioCategoryOptions.WaterFlow,
        5,
        'Domestic Hot Water',
        'relative',
        scenarioUnitOptions.LHM2,
      ),
      specificElectricity: buildScenarios(
        scenarioCategoryOptions.ElectricLoad,
        6,
        'Specific Electricity',
        'relative',
        scenarioUnitOptions.WM2,
      ),
    };
  }
}
